import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import CardContent from "@mui/material/CardContent";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid, Divider, Stack } from '@mui/material';
import axios from 'axios';
import ListItem from '@mui/material/ListItem';
// import pages
import Updatepage from '../updatepage';
import { headers } from '../../../../../redux/Actions/connectActions';
import CreatePost from '../../../../Molecule/Feed/createpost';
import AdminPublicationNew from '../amenities/adminPublicationNew';
import { getMetaData, getPageById } from '../../../../../redux/Actions/actions';
import UserService from '../../../../../services/UserService';
import InputField from '../../../../Fields/TextFields';
import DialogBox from '../../../../DialogBox/dialog';
import URL from '../../../../apiurl';
// import icons
import { FiEdit3 } from 'react-icons/fi';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Feed from '../../../../Molecule/Feed/Feed';
import Events from '../../../../../icons/eventsicon.svg';
import Doctors from '../../../../../icons/doctors.svg';
import Globe from '../../../../../icons/globe.svg';
import Computer from '../../../../../icons/computer.svg';
import location_on from '../../../../Atom/modifiedicons/location_on.svg'
import Mail from '../../../../Atom/modifiedicons/Mail.svg';
import call from '../../../../Atom/modifiedicons/call.svg';
// import css
import '../../myprofile.css';
import '../../../../Molecule/Feed/Feed.css';
import '../../../../utility/style.css';
import '../organization.css';
import '../AdminOrganization/adminTimeLine.css';
import { D_API } from '../../../../../services/APIService';
import { URI } from '../../../../utility/uri';


let emptydata = {
    "date": new Date(),
    "description": "",
    "id": 0,
    "location": "",
    "metadata": {},
    "coverPhoto": "",
    "kycStatus": "PENDING",
    "followersCount": 0,
    "organisation": {
        "resourceType": "Organization",
        "name": "",
        "identifier": [
            {
                "system": "",
                "type": {
                    "text": "",
                    "coding": [{
                        "display": "",
                    }]
                },
                "value": "",
            }

        ],
        "type": [
            {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/organization-type",
                        "display": ""
                    }
                ]
            }
        ],
        "telecom": [
            {
                "system": "",
                "value": "",
                "use": ""
            }
        ],
        "address": [
            {
                "use": "",
                "line": [""],
                "country": "",
                "state": "",
                "city": "",
                "district": "",
                "postalCode": ""
            }
        ],
        "contact": [
            {
                "purpose": {
                    "coding": [
                        {
                            "system": "http://terminology.hl7.org/CodeSystem/contactentity-type",
                            "code": "ADMIN"
                        }
                    ]
                },
                "name": {
                    "text": ""
                },
                "telecom": [],
                "address": {}
            }
        ]
    },
    "pageName": "",
    "orgPageImage": "",
    "pageAdmin": `${sessionStorage.getItem("userId")}`,
    "pagePortal": "",
    "pageStatus": "ACTIVE",
    "pageStatusUpdatedDate": new Date(),
    "pageWebsite": "",
    "status": true,
    "userId": Number(sessionStorage.getItem("userId"))
}


export default function AdminTimeline() {

    const [value, setValue] = useState(emptydata)
    const [page, setPage] = useState(false)
    const [data, setData] = useState({});
    const [about, setAbout] = useState(false)
    const [desc, setDesc] = useState({})
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const dispatch = useDispatch();
    const pageData = useSelector(state => state.userProfile.pageData)
    let { id } = useParams();
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const state = useSelector(state => state.userProfile.pageData)
    const userOrg = useSelector(state => state.registration.userOrg);
    console.log(userOrg, "adminTimline", pageData)
    // console.log(state, "pageData")
    const getpeopledata = useSelector(state => state?.userProfile?.findpeoplebypage)
    const contact = state.hasOwnProperty("organisation") ? state?.organisation?.telecom : "";
    const address = state.hasOwnProperty("organisation") ? state?.organisation?.address?.[0] : "";
    const [portal, setPortal] = useState({})
    const [edit, setEdit] = useState(false)
    const eventdata = useSelector(state => state?.userProfile?.eventsbypageid)
    const [eventcount, setEventcount] = useState(3);
    const [selectedTimelineType, setSelectedTimelineType] = useState("All");

    useEffect(() => {
        ValidatorForm.addValidationRule('urlMatch', (value) => {
            if (value !== null) {
                const regex = new RegExp('^(http|https|ftp)\://[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9\-\._\?\,\'/\\\+&amp;%\$#\=~])*$');
                let val = regex.test(value)
                if (!val) {
                    return false;
                }
                return true;
            }
            else {
                return true
            }
        });
    }, [])

    useEffect(() => {
        if (userOrg?.id) {
            D_API.get(`${userOrg?.id}/${URI.getPermissionForPractitionerRole}${userOrg?.id}`).then((res) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: res.data
                })
            }).catch((err) => {
                dispatch({
                    type: "IS_ADMIN",
                    payload: null
                })
                console.log(err)
            })

        } else {
            dispatch({
                type: "IS_ADMIN",
                payload: null
            })
        }



    }, [userOrg?.id])

    useEffect(() => {
        if (UserService?.getToken()) {
            if (Object.keys(pageData)?.length > 0) {
                if (pageData.status === true) {
                    setPortal(pageData)
                    setDesc(pageData)
                    setPageData(pageData)
                }
                else {
                    history.push(`/organization/${id}`)
                }
            } else {
                dispatch(getPageById(id))
            }
        }

    }, [pageData])

    const setPageData = (pageData) => {
        let valuedata = {
            name: pageData?.organisation?.name || '',
            assigner: pageData?.organisation?.identifier?.[0]?.system || '',
            idType: pageData?.organisation?.identifier?.[0]?.type?.coding?.[0]?.display || '',
            id: pageData?.organisation?.identifier?.[0]?.value || '',
            orgType: pageData?.organisation?.type?.[0]?.coding?.[0]?.display || '',
            // text: pageData?.organisation?.identifier[0].type ? pageData?.organisation?.identifier[0].type.text : '',
            pageName: pageData?.pageName,
            caption: pageData?.organisation?.identifier?.[0]?.type?.text || '',
            // display: pageData?.organisation?.type[0].coding[0].display,
            value: pageData?.organisation?.telecom?.[0] ? pageData?.organisation?.telecom?.[0].value : '' || '',
            // system: pageData?.organisation?.telecom[0].system,
            location: pageData.location || '',
            // person: pageData?.organisation?.contact[0].name.text,
            // // use: pageData?.organisation?.telecom[0].use,
            // role: pageData?.organisation?.contact[0].purpose.coding[0].code,
            orgPageImage: pageData?.orgPageImage || '',
            Use: pageData?.organisation?.address?.[0]?.use || '',
            // line1: pageData?.organisation?.address[0]?.line[0],
            // line2: pageData?.organisation?.address[0]?.line[1],
            city: pageData?.organisation?.address?.[0]?.city || '',
            district: pageData?.organisation?.address?.[0]?.district || '',
            state: pageData?.organisation?.address?.[0]?.state || '',
            postalCode: pageData?.organisation?.address?.[0]?.postalCode || '',
            country: pageData?.organisation?.address?.[0]?.country || '',
            description: pageData.description || '',
            telecom: pageData?.organisation?.telecom || '',
            contact: pageData?.organisation?.contact || '',
        }
        setData(valuedata)
        let mydata1 = {
            coverPhoto: pageData?.coverPhoto,
            name: valuedata?.name,
            pageName: valuedata?.pageName,
            orgType: valuedata?.orgType,
            // display: valuedata.display,
            // value: valuedata.value,
            location: valuedata?.location,
            // person: valuedata.person,
            // role: valuedata.role,
            assigner: valuedata?.assigner,
            idType: valuedata?.idType,
            id: valuedata?.id,
            caption: valuedata?.caption,
            // use: valuedata.use,
            // system: valuedata.system,
            orgPageImage: valuedata?.orgPageImage,
            description: valuedata?.description,
            address: {
                Use: valuedata?.Use,
                line: [valuedata?.line1, valuedata?.line2],
                city: valuedata?.city,
                district: valuedata?.district,
                state: valuedata?.state,
                postalCode: valuedata?.postalCode,
                country: valuedata?.country,
            },
            telecom: pageData?.organisation?.telecom,
            contact: valuedata?.contact,
            pagePortal: pageData?.pagePortal,
            pageWebsite: pageData?.pageWebsite,
            metadata: pageData?.metadata,
            pageAdmin: pageData?.pageAdmin,
            pageStatus: pageData?.pageStatus,
            followersCount: pageData?.followersCount,
            kycStatus: pageData?.kycStatus,
            pageStatusUpdatedDate: pageData?.pageStatusUpdatedDate
        }
        setValue(mydata1)
    }

    const isopenupdatepage = (value) => {
        let objorg = {
            name: value?.data?.organisation?.name,
            assigner: value?.data?.organisation?.identifier?.[0]?.system,
            idType: value?.data?.organisation?.identifier?.[0]?.type?.coding?.[0]?.display,
            id: value?.data?.organisation?.identifier?.[0]?.value,
            orgType: value?.data?.organisation?.type?.[0]?.coding?.[0]?.display,
            caption: value?.data?.organisation?.identifier?.[0]?.type?.text,
            // text: value.data.organisation?.identifier?.[0]?.type.text,
            pageName: value?.data?.pageName,
            // partOf: value.data.organisation.partOf.reference,
            // display: value.data.organisation.type[0].coding[0].display,
            value: value?.data?.organisation?.telecom?.[0] ? value.data?.organisation?.telecom?.[0]?.value : '',
            // system: value.data.organisation.telecom[0].system,
            location: value?.data?.location,
            // person: value.data.organisation.contact[0].name.text,
            // use: value.data.organisation.telecom[0].use,
            // role: value.data.organisation.contact[0].purpose.coding[0].code,
            orgPageImage: value?.data?.orgPageImage,
            Use: value?.data?.organisation?.address?.[0]?.use,
            line1: value?.data?.organisation?.address?.[0]?.line?.[0],
            line2: value?.data?.organisation?.address?.[0]?.line?.[1],
            city: value?.data?.organisation?.address?.[0]?.city,
            district: value?.data?.organisation?.address?.[0]?.district,
            state: value?.data?.organisation?.address?.[0]?.state,
            postalCode: value?.data?.organisation?.address?.[0]?.postalCode,
            country: value?.data?.organisation?.address?.[0]?.country,
            description: value?.data?.description,
            telecom: value?.data?.organisation?.telecom,
            contact: value?.data?.organisation?.contact
        }

        setData(objorg)
        let updatedorganization = {
            name: objorg?.name,
            pageName: objorg?.pageName,
            orgType: objorg?.orgType,
            caption: objorg?.caption,
            // value: objorg.value,
            location: objorg?.location,
            // person: objorg.person,
            // role: objorg.role,
            assigner: objorg?.assigner,
            idType: objorg?.idType,
            id: objorg?.id,
            // text: objorg.text,
            // use: objorg.use,
            // system: objorg.system,
            orgPageImage: objorg?.orgPageImage,
            description: objorg?.description,
            address: {
                Use: objorg?.Use,
                line: [objorg?.line1, objorg?.line2],
                city: objorg?.city,
                district: objorg?.district,
                state: objorg?.state,
                postalCode: objorg?.postalCode,
                country: objorg?.country,
            },
            telecom: objorg?.telecom,
            contact: objorg?.contact,
            metadata: value?.data?.metadata
        }
        setValue(updatedorganization)
    }

    const updateOrg = async () => {
        axios.put(`${URL.updatePageById}/${id}`, { ...portal, metadata: await getMetaData(portal.metadata) }, headers()).then(res => {
            // console.log(res)
            setPortal(res.data?.data)
            setPageData(res.data?.data)
            setEdit(false)
            dispatch(getPageById(id))
        })
    }

    const submitAbout = async () => {
        // console.log("567890")
        axios.put(`${URL.updatePageById}/${id}`, { ...desc, metadata: await getMetaData(desc.metadata) }, headers()).then(res => {
            // console.log(res)
            setDesc(res.data?.data)
            setPageData(res.data?.data)
            setAbout(false)
            dispatch(getPageById(id))
        })
    }
    const handlePopup = (key) => {
        setOpen(key)
        // setOpen(false)
    }

    const handleopen = () => {
        setOpen(true)
    }

    const openWebinarfn = (event) => {
        window.open(`${process.env.REACT_APP_WEBINAR}/webinar/room/${event?.title}/${event?.id}`)
    }

    // console.log(data)

    return (
        <>
            {/* <Card sx={{ p: { xs: "10px", sm: " 10px " } }}> */}

            {
                open ? <CreatePost keystate={open} handleClose={() => setOpen('')} /> : ""
            }
            <DialogBox open={edit} handleDialog={() => setEdit(false)}
                Title="Edit Page Details"
                Content={
                    <ValidatorForm onSubmit={() => updateOrg()}>
                        <hr />
                        <InputField
                            label="Website URL"
                            value={portal.pageWebsite} name="designation" handleChange={(name, value) => setPortal({ ...portal, pageWebsite: value })}
                            validators={['urlMatch']}
                            errorMessages={['Please Enter valid URL']}
                        />
                        <InputField
                            label="Patient Portal"
                            value={portal.pagePortal} name="designation" handleChange={(name, value) => setPortal({ ...portal, pagePortal: value })}
                        // validators={['urlMatch']}
                        // errorMessages={['Please Enter valid URL']}
                        />
                        <div style={{ textAlign: 'right' }}>
                            <Button type="submit">Update</Button>
                            <Button onClick={() => setEdit(false)}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                }
            />
            <DialogBox open={about} handleDialog={() => setAbout(false)}
                Title="About"
                Content={
                    <ValidatorForm
                        onSubmit={() => submitAbout()}
                    >
                        <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            name="description"
                            value={desc.description}
                            // handleChange={(e) => handleform("idnumber", e.target.value)}
                            onChange={(e) => setDesc({ ...desc, description: e.target.value })}
                        />
                        <div style={{ textAlign: 'right', marginTop: "10px" }}>
                            <Button type="submit" className='portal_update cursor'>Update</Button>
                            <Button className='portal_update cursor' onClick={() => setAbout(false)}>Cancel</Button>
                        </div>
                    </ValidatorForm>
                }
            />
            {/* DESKTOP && LARGE SCREEN */}

            <Grid container spacing={2} className='DesktopDevices_orz' >
                {/* <Grid item md={4} style={{ paddingRight: "10px" }}>
                    <div className='border_img mt-10'>
                        <AdminPublicationNew pageUserId={pageData?.pageAdmin}/>
                    </div>
                </Grid> */}
                <Grid item sm={3} md={3} lg={3}>
                    <Card style={{ height: '100vh', borderRadius: "10px", boxShadow: "0px 0px 1.6px #888", }} className='mt1'>
                        <div className='p1' >
                            <Stack spacing={1}>
                                <div className={selectedTimelineType == "All" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("All")}><span className='pl1 fntSm14 capitalize '>All</span></div>
                                <div className={selectedTimelineType == "ARTICLE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("ARTICLE")} ><span className='pl1 fntSm14  capitalize '>Articles</span></div>
                                <div className={selectedTimelineType == "EVENT" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("EVENT")} ><span className='pl1 fntSm14  capitalize'>Events</span></div>
                                <div className={selectedTimelineType == "POLL" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("POLL")} ><span className='pl1 fntSm14  capitalize'>Poll</span></div>
                                <div className={selectedTimelineType == "IMAGE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("IMAGE")} ><span className='pl1 fntSm14  capitalize'>Images</span></div>
                                <div className={selectedTimelineType == "VIDEO" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("VIDEO")} ><span className='pl1 fntSm14  capitalize'>Videos</span></div>
                                <div className={selectedTimelineType == "TEXT" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("TEXT")} ><span className='pl1 fntSm14  capitalize'>Text</span></div>
                                <div className={selectedTimelineType == "CELEBRATE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("CELEBRATE")} ><span className='pl1 fntSm14  capitalize'>Celebrations</span></div>

                            </Stack>
                        </div>
                    </Card>

                </Grid>
                <Grid item sm={9} md={9} lg={9} xs={12}>
                    {(page === true) ? <Updatepage open={page} handleDialog={() => setPage(false)} mydata1={value} isopenupdatepage={isopenupdatepage} text="updatedform" /> : ""}
                    {/* feed */}
                    <div className='display_feed' style={{ width: '100%' }}>
                        <div >
                            <Feed view={true} selectedPostType={selectedTimelineType} />
                        </div>
                    </div>
                </Grid>

            </Grid>
            {/* TABLET && MOBILES */}

            <Grid container className='SmallDevices_orz' sx={{ overflow: "scroll", height: `calc(100vh - 64px)` }}>
                <Grid item xs={12} sm={12} >
                    <Card sx={{ borderRadius: "10px", boxShadow: "0px 0px 1.6px #888", }}>
                        <div className='p1' >
                            <Stack spacing={1}>
                                <div className={selectedTimelineType == "All" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("All")}><span className='pl1 fntSm14 capitalize '>All</span></div>
                                <div className={selectedTimelineType == "ARTICLE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("ARTICLE")} ><span className='pl1 fntSm14  capitalize '>Articles</span></div>
                                <div className={selectedTimelineType == "EVENT" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("EVENT")} ><span className='pl1 fntSm14  capitalize'>Events</span></div>
                                <div className={selectedTimelineType == "POLL" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("POLL")} ><span className='pl1 fntSm14  capitalize'>Poll</span></div>
                                <div className={selectedTimelineType == "IMAGE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("IMAGE")} ><span className='pl1 fntSm14  capitalize'>Images</span></div>
                                <div className={selectedTimelineType == "VIDEO" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("VIDEO")} ><span className='pl1 fntSm14  capitalize'>Videos</span></div>
                                <div className={selectedTimelineType == "TEXT" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("TEXT")} ><span className='pl1 fntSm14  capitalize'>Text</span></div>
                                <div className={selectedTimelineType == "CELEBRATE" ? "active" : "inActive"} onClick={() => setSelectedTimelineType("CELEBRATE")} ><span className='pl1 fntSm14  capitalize'>Celebrations</span></div>

                            </Stack>
                        </div>
                    </Card>

                </Grid>
                <Grid item xs={12} sm={12}>
                    {/* {(page === true) ? <Updatepage open={page} handleDialog={() => setPage(false)} mydata1={value} isopenupdatepage={isopenupdatepage} text="updatedform" /> : ""} */}
                    {/* feed */}

                    <div className="" style={{ width: '100%' }}>
                        <div className="about__head">
                            <Feed view={true} selectedPostType={selectedTimelineType} />
                        </div>
                    </div>
                </Grid>

            </Grid>

            {/* <Grid container className='SmallDevices_orz' >
                <Grid item xs={12}>

             

                    <div className="about_padding" style={{ padding: "10px 20px" }}>
                        <div className='space_btw_center'>
                            <p className='about_org_text'>About</p>
                            <IconButton aria-label="visible" onClick={() => setAbout(true)}>
                                <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                            </IconButton>
                        </div>
                        {data.description ?
                            <div style={{ padding: "20px" }}>
                                <span>{isReadMore && data?.description ? data?.description?.substr(0, 300) : data?.description}</span>
                                <span onClick={toggleReadMore} className="cursor" style={{ fontWeight: "600", fontSize: "14px" }}>{isReadMore && data?.description?.length > 300 ? "...Read More" : ""}</span>
                            </div> :
                            <p className="about___text">Write some thing about your Organisation ?</p>
                        }
                    </div>

                    <div className='border_img mt-10'>
                        <div className='space_btw_center' style={{ padding: "10px 20px" }}>
                            <div className='space_around_center'>
                                <img alt="event" src={Events} width="18px" height="18px"></img>
                                <span className='job_text'>Upcoming Events</span>
                            </div>
                            <div>
                                <Button className='post_job' onClick={() => { handleopen(); handlePopup('event_form') }} >Add event</Button>
                            </div>
                        </div>
                        <div>
                            <div className="sidebar__recent upcomming_event_padding">
                                {eventdata && eventdata?.[0]?.upcomingEvents !== null ? eventdata?.[0]?.upcomingEvents?.slice(0, eventcount)?.map((event, i) => (
                                    <div key={event.id}><div className="profile__top sidebar__pb pl-1" style={{ alignItems: 'end' }}>
                                        <div>
                                            <p className="capitalize font-bold">{event.title}</p>
                                            <p className="font-title" style={{ paddingTop: '5px' }}>{moment.utc(event.openingTime).local().format('DD-MM-YYYY hh:mm A')}</p>
                                        </div>
                                        {new Date(moment.utc(event.openingTime).local()) > new Date() ?
                                            <Button variant='outlined' sx={{ p: '0!important' }} disabled>{event.eventAdmin === UserService.getUserId() ? 'Start' : "Start"}</Button> :
                                            <button className="join-webinar cursor" onClick={() => openWebinarfn(event)}>{event.eventAdmin === UserService.getUserId() ? 'Start' : "Start"}</button>
                                        }
                                    </div>
                                        {i !== eventcount - 1 && i !== eventdata?.[0]?.upcomingEvents?.length - 1 ?
                                            <Divider sx={{ mb: '10px' }} /> :
                                            <>
                                                {eventcount !== eventdata?.[0]?.upcomingEvents?.length && eventdata?.[0]?.upcomingEvents?.length > 3 ? <p className='add-cover cursor see-more textCenter' style={{ color: '#700eca' }} onClick={() => setEventcount(eventdata?.[0]?.upcomingEvents?.length)}>ShowAll</p> : ''}
                                            </>
                                        }
                                    </div>
                                )) : <div>no data</div>}
                            </div>
                        </div>
                    </div>

                    <div className='border_img mt-10'>

                        <div className="bg__white sidebar__padding">
                            <div className="sidebar__statNumber see-more">
                                <IconButton aria-label="visible" onClick={() => setEdit(true)}>
                                    <FiEdit3 style={{ color: "rgb(46, 59, 67)" }} />
                                </IconButton>
                            </div>
                            <div className="sidebar__stat">
                                <div className="sidebar__statNumber">
                                    <div className="sidebar__substat ta__center">
                                        <div style={{ color: 'black' }}>
                                            <span style={{ display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "400" }}><img alt="website" src={Globe} width="25px" height="25px" style={{ paddingRight: "10px" }}></img>Website</span>
                                            <span className="d-flex ta__left mt-10">
                                                <span className='portal_text'>{state.pageWebsite ? <Link to={{ pathname: state.pageWebsite }} target="_blank" className="active-link">{state.pageWebsite}</Link> : 'N/A'}</span>
                                            </span>
                                            {state?.pagePortal ?
                                                <>
                                                    <span style={{ display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "400", marginTop: "10px" }}><img src={Computer} alt="portal" width="25px" height="25px" style={{ paddingRight: "10px" }}></img>Patient Portal</span>
                                                    <span className="d-flex ta__left mt-10">
                                                        <span className='portal_text'>
                                                            {state.pagePortal ? <Link to={{ pathname: state.pagePortal }} target="_blank" className="active-link">{state.pagePortal}</Link> : 'N/A'}
                                                        </span>
                                                    </span>
                                                </> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='border_img mt-10'>
                        <div style={{ display: "flex", alignItems: "center", backgroundColor: "#fff" }}>
                            <h3 className='fw__normal' style={{ fontSize: "18px", fontWeight: "550", color: "rgb(35, 122, 196)", padding: "12px 12px 0px 20px" }}>Contact Details</h3>
                        </div>
                        <div className='sidebar__padding' style={{ backgroundColor: "white" }}>

                            <div className="sidebar__stat sidebar__pt">
                                <div className="sidebar__substat">
                                    <img src={location_on} alt="" style={{ fontSize: "16px" }} />
                                    <div className="sidebar__title ml-2" style={{ fontSize: "16px", fontWeight: "400", color: "#36454F" }}>
                                        {address?.city || address?.state || address?.country ?
                                            <>
                                                <span>{address.city ? address.city : ''}</span>{" "}

                                                {address.city && address.state ? ',' : ''}<span>{address.state ? address.state + ',' : ''}</span>{" "}

                                                <span>{address.country ? address.country : ''}</span>

                                            </> : 'N/A'}
                                    </div>
                                </div>
                            </div>

                            <div className="sidebar__stat pt-2 sidebar__pt">
                                <div className="sidebar__substat ta__center">
                                    <img src={Mail} alt="" style={{ fontSize: "16px" }} />
                                    <div className="ml-2">

                                        {
                                            (contact?.[0]?.system === "Email") ?
                                                <p className='email_text'>

                                                    {contact?.[0] && contact?.length > 0 ?
                                                        (contact?.[0]?.value)
                                                        : ('N/A')}</p>
                                                :
                                                (contact?.[1]?.system === "Email") ?
                                                    <p className='email_text'>

                                                        {contact?.[1] && contact?.length > 0 ? (contact?.[1]?.value)
                                                            : ('N/A')}</p>
                                                    : <p className='email_text'>N/A</p>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="sidebar__stat sidebar__pt">
                                <div className="sidebar__substat ta__center sidebar__pt">
                                    <img src={call} alt="" style={{ fontSize: "16px" }} />
                                    <div className="ml-2">

                                        {
                                            (contact?.[0]?.system === "Phone") ?
                                                <p className='email_text'>
                                                    {contact?.[0] && contact?.length > 0 ? (contact?.[0]?.value)
                                                        : ('N/A')}</p>
                                                :
                                                (contact?.[1]?.system === "Phone") ? <p className='email_text'>
                                                    {contact?.[1] && contact?.length > 0 ? (contact?.[1]?.value)
                                                        : ('N/A')}</p> : <p className='email_text'>N/A</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='mt-20'>
                                <h3 className='fw__normal' style={{ color: "#5119A2", fontSize: "18px" }}>Social Accounts</h3>
                            </div>
                            <div className='mt-10' style={{ display: "flex", alignItems: "center" }}>
                                <div>
                                    <TwitterIcon style={{ color: 'rgb(29, 155, 240)', width: "25px", height: "25px", paddingRight: "10px" }} />
                                </div>
                                <div>
                                    <FacebookIcon style={{ color: '#385898', width: "25px", height: "25px", paddingRight: "10px" }} />
                                </div>
                                <div>
                                    <LinkedInIcon style={{ color: '#007DCD', width: "25px", height: "25px", paddingRight: "10px" }} />
                                </div>
                                <div>
                                    <WhatsAppIcon style={{ color: 'green', width: "25px", height: "25px", paddingRight: "10px" }} />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="side_head" style={{ marginBottom: "100px" }}>
                        <div className="sidebar__stat people_header sidebar__padding">
                            <div className="sidebar__substat ta__center">
                                <img alt="people" src={Doctors}></img>
                                <div className="people_text">People</div>
                            </div>
                        </div>
                        <div>
                            <ListItem sx={{ maxHeight: '100%', height: 'auto', overflow: 'auto', pt: 0 }}>
                                <Grid container className='cards_flex'>
                                    {getpeopledata?.length > 0 ?
                                        getpeopledata?.map((value, index) => {
                                            return (
                                                <Grid item md={3} sm={12} xs={12} className="card_followers_margin" key={index}>
                                                    <Card className='follow_hgt_wdth'>
                                                        <CardContent className='card_connent_profile'>
                                                            <div style={{ textAlign: "center", paddingTop: "10px" }}>
                                                                <img alt="profile" src={value?.profilePic} className="following_images"></img>
                                                            </div>
                                                            <div>
                                                                <p className='doctorname_text mt-10' style={{ textAlign: "center" }}>{value?.fullName}</p>
                                                                <p className='doctors_tagline mt-10' style={{ textAlign: "center", fontSize: "14px" }}>{value?.captionAndTagline}</p>
                                                            </div>
                                                            <div className='pt-2' style={{ display: "flex", justifyContent: "center" }}>
                                                                <Link to={`/profile/${value?.fullName?.replace(/ /g, '')?.toLowerCase()}-${value.userId}`}>
                                                                    <Button className='viewprofile_btn'>View Profile</Button>
                                                                </Link>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                        }) : ""

                                    }

                                </Grid>
                            </ListItem>
                        </div>

                    </div>
                </Grid>
            </Grid> */}
            {/* </Card > */}
        </>
    )
}
