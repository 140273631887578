import { React } from "react";
import { Card, Grid, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from "react-redux";
import HealthDepartmentPost from "../feedFolderInDept/postInDept";

const PostsAndArticles = () => {

    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    const orgData = useSelector(state => state.registration.userOrgData);
    console.log(healthServiceOrg, "5")
    return (
        <div>

            <div className="mt2">
                <Card sx={{ padding: "10px", minHeight: "150px", boxShadow: "0px 0px 1.6px #888", }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div>
                                <Typography className="capitalize" sx={{
                                    fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                    "&.MuiTypography-root": {
                                        lineswhiteSpace: 0,
                                        fontFamily: "unset"
                                    }

                                }}>Posts & Articles</Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: "2px" }}>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" />
                            </div>
                        </Grid>
                    </Grid>

                    <HealthDepartmentPost PageId={orgData?.identifier?.[1]?.value} />

                </Card>
            </div>

        </div >
    )
}
export default PostsAndArticles;