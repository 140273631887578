import URL from "../apiurl";

class PDFTool {
    static get toolbox() {
        return {
            title: 'PDF',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 17v2h10v-2H7z"/><path d="M5 3v18h14V3H5zm12 16H7V5h10v14z"/></svg>',
        };
    }

    constructor({ data }) {
        this.data = data || {};
        this.wrapper = undefined;
        this.fileInput = undefined;
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('pdf-tool');

        // Create file input for PDF upload
        this.fileInput = document.createElement('input');
        this.fileInput.type = 'file';
        this.fileInput.accept = '.pdf';
        this.fileInput.addEventListener('change', this.handleFileChange.bind(this));

        this.wrapper.appendChild(this.fileInput);

        if (this.data?.url) {
            const fileLink = document.createElement('a');
            fileLink.href = this.data.url;
            fileLink.target = '_blank';
            fileLink.textContent = 'View PDF';
            this.wrapper.appendChild(fileLink);
        }

        return this.wrapper;
    }

    handleFileChange(e) {
        const file = e.target.files[0];

        if (e.target.files.length > 0) {
            let types = e.target.files?.[0]?.type?.split("/")[1];
            const eTypes = types === 'pdf';

            if (eTypes) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = () => {
                    console.log(reader.result, "result");
                    let base64 = reader.result.split(",")[1].split("=")[0];

                    var strLength = base64.length;
                    var fileLength = strLength - (strLength / 8) * 2;
                    const [, type] = reader.result.split(";")[0].split('/');

                    // Call the file upload function
                    this.fileUpload(base64, type); // Correctly using `this.fileUpload`
                };
            } else {
                alert('Please upload a PDF file');
            }
        }
    }

    fileUpload(file, type) {
        // Prepare data for file upload
        let data = {
            "file": [file],
            "fileType": type,
            "multiple": false,
            "subType": "",
            "type": ""
        };

        console.log(URL.upload, "upload");

        // Use `fetch` to send data to the server
        fetch(`${URL.uploadFile}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify(data),
        })
        .then(res => res.json())
        .then(res => {
            console.log(res, "success");
            // Update the editor with the uploaded file URL
            if(res.data){
                this.data={
                    url:res.data
                }
            }
        })
        .catch(err => {
        }).catch(err=>{
            alert("Uploading failed, try again.");
        });
    }

    save() {
        return this.data;
    }
}

export default PDFTool;
