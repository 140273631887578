// import { makeStyles, styled } from '@mui/material/styles';
import { makeStyles, styled } from '@mui/styles';
import SearchImg from "../../Atom/svg/search.svg";
import InputBase from '@mui/material/InputBase';
import URL from '../../apiurl';
import axios from "axios";
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import "./search.css";
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Building from '../../../images/building.svg'
import hashtag_icon from '../../Atom/modifiedicons/hashtag.svg'
import upcoming from '../../Atom/modifiedicons/upcoming.svg'
import CircularProgress from '@mui/material/CircularProgress';
import OrgLogo from '../../../images/orglogo.svg'
// import profimage from '../../../icons/Userprofilelogo.svg';
import profimage from '../../../icons/Userprofilelogo.svg'
import UserService from '../../../services/UserService';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { D_API } from '../../../services/APIService';
import { URI } from '../../utility/uri';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: '0px 2px 3px lightgrey',
        top: '5px!important',
        [theme.breakpoints?.between('md', 'xl')]: {
            width: '45.5%',
        },
        [theme.breakpoints?.between('sm', 'md')]: {
            width: '48%',
        },
        [theme.breakpoints?.between('xs', 'sm')]: {
            width: '100%'
        }
    }
}));

const ChipData = ({ label, handleFilter, value, a }) => {

    return (
        value ? <Chip label={label} style={{ marginBottom: '10px' }} size="small" color={"secondary"} variant={"filled"} onDelete={() => handleFilter('')} />
            :
            <Chip label={label} style={{ marginBottom: '10px' }} size="small" color={"default"} variant={"outlined"} onClick={(e) => handleFilter(a)} />
    )
}

// const Search = makeStyles('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme?.shape?.borderRadius,
//     backgroundColor: ' #e1f3ff',
//     '&:hover': {
//         backgroundColor: '#e1f3ff',
//     },
//     marginRight: theme.spacing(2),
//     width: '98%',
//     [theme.breakpoints.up('lg')]: {
//         marginLeft: theme.spacing(3)
//     },
//     [theme.breakpoints.up('lg')]: {
//         width: '60%',
//     },
// }));
const Search = makeStyles((theme) => ({
    root: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#e1f3ff',
        '&:hover': {
            backgroundColor: '#e1f3ff',
        },
        marginRight: theme.spacing(2),
        width: '98%',
        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.spacing(3),
            width: '60%',
        },
    },
}));


const SearchComp = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme?.shape?.borderRadius,
    backgroundColor: ' #e1f3ff',
    '&:hover': {
        backgroundColor: '#e1f3ff',
    },
    marginRight: '10px',
    marginLeft: '10px',
    width: '98%',
}));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     // padding: theme.spacing(0, 1),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));
// const SearchIconWrapper = makeStyles((theme) => ({
//     searchIconWrapper: {
//         padding: theme.spacing(0, 1),
//         height: '100%',
//         position: 'absolute',
//         pointerEvents: 'none',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//     },
// }));
const customStyles = makeStyles((theme) => ({
    searchIconWrapper: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));



const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 'inherit',
            color: 'black!important'
        },
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#e1f3ff',
        '&:hover': {
            backgroundColor: '#e1f3ff',
        },
        marginRight: theme.spacing(2),
        width: '98%',
        [theme.breakpoints.up('lg')]: {
            marginLeft: theme.spacing(3),
            width: '60%',
        },
    },
    searchIconWrapper: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // right: theme.spacing(2),
    },
    inputBase: {
        color: 'black',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 'inherit',
                color: 'black!important',
            },
        },
    },

    searchComp: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#e1f3ff',
        '&:hover': {
            backgroundColor: '#e1f3ff',
        },
        marginRight: '10px',
        marginLeft: '10px',
        width: '98%',
    },
}));



export default function SearchComponent(props) {
    // console.log(props.width)
    const [searchOptions, setSearchOptions] = React.useState([])
    console.log(searchOptions, "sss")
    const history = useHistory();
    const state = useSelector(state => state.userProfile.userProfile);
    const orgData = useSelector((state) => state.registration.userOrg);
    console.log(state, "state");
    const [name, setName] = React.useState('')
    const [optn, setOptn] = React.useState([])
    const [key, setKey] = useState('')
    const [load, setLoad] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const styles = useStyles()
    const classes1 = useStyles1()
    const classes = customStyles()
    const classess = Search()
    // const SearchIconWrapper1 = SearchIconWrapper()




    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchOptions([])
        setName('')
    };

    const getSearchOrganizationDetails = async (pageId) => {
        let isAccess = null;
        try {
            const res = await D_API.get(`${URI.fetchOrganizationDetails}${pageId}`);
            // console.log(res.data?.entry?.[0]?.resource?.id, "samm");
            let orgId = res.data?.entry?.[0]?.resource?.id;

            if (orgId) {
                isAccess = await NowWhetherIsAdmin(orgId);  // Wait for the result of NowWhetherIsAdmin
                console.log(isAccess, "hijk");
            } else {
                isAccess = null;
            }
        } catch (err) {
            console.log(err);
            isAccess = null;
        }
        return isAccess;
    }

    const NowWhetherIsAdmin = async (ide) => {
        try {
            const res = await D_API.get(`${ide}/${URI.getPermissionForPractitionerRole}${ide}`);
            console.log(res.data, "vijay");
            return res.data;  // Return the result of the API call
        } catch (err) {
            console.log(err);
            return null;
        }
    }


    const searchChange = (value) => {
        let name = value
        setName(name)
        let data = {
            search: name
        }
        if (name.length > 2) {
            setLoad(true)
            axios.post(`${URL.searchUsers}`, data, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
                }
            }).then(res => {
                if (res.status === 200) {
                    setOptn(res.data.data)
                    setSearchOptions(res.data.data)
                }
                setLoad(false)
            }).catch(err => setLoad(false))
        }
        else {
            setSearchOptions([])
        }
    }


    const userSelect = async (val) => {  // Marking userSelect as async
        console.log(val, 'valll');
        if (val.type === "people") {
            if (state.id === val.id) {
                history.push(`/myprofile/${val.name.replace(/ /g, '').toLowerCase()}-${val.id}`);
            } else {
                history.push(`/profile/${val.name.replace(/ /g, '').toLowerCase()}-${val.id}`);
            }
        } else if (val.type === "organisation") {
            let isPermit;
            try {
                isPermit = await getSearchOrganizationDetails(val?.id);
                console.log(isPermit);
            } catch (err) {
                isPermit = null;
                console.log(err);
            }

            console.log("DLF", isPermit);


            if (isPermit === "PERMIT") {
                history.push(`/admin/organization/${val.id}`)
            } else {
                history.push(`/organization/${val.id}`)
            }
            // old code
            // if (val.userId == UserService.getUserId) {
            //     history.push(`/admin/organization/${val.id}`)
            // } else {
            //     history.push(`/organization/${val.id}`)
            // }
        }
        handleClose();
    };


    // const userSelect = ((val) => {
    //     console.log(val, 'valll')
    //     if (val.type === "people") {
    //         if (state.id === val.id) {
    //             history.push(`/myprofile/${val.name.replace(/ /g, '').toLowerCase()}-${val.id}`)
    //         } else {
    //             history.push(`/profile/${val.name.replace(/ /g, '').toLowerCase()}-${val.id}`)
    //         }
    //     } else if (val.type === "organisation") {

    //         let isPermit;
    //        getSearchOrganizationDetails(val?.id).then(result => {
    //             isPermit= result;
    //             console.log(isPermit);  // This will log the value after the async operation is complete.
    //         }).catch(err => {
    //             isPermit =null
    //             console.log(err);  // Handle any errors
    //         });
    //         console.log("DLF", isPermit)
    //         // NowWhetherIsAdmin()
    //         // if (val.userId == UserService.getUserId) {
    //         //     history.push(`/admin/organization/${val.id}`)
    //         // } else {
    //         //     history.push(`/organization/${val.id}`)
    //         // }
    //     }
    //     handleClose()
    // })

    const handleFilter = (value) => {
        setKey(value)
        let options = [...optn]
        if (value !== '') {
            options = options.filter(val => val.type === value)
            setSearchOptions(options)
        }
        else {
            searchChange(name)
        }
    }

    return (

        <div style={{ flexGrow: 1 }}>
            {/* <Search sx={{ width: props.width }}
                id="demo-positioned-button"
                aria-controls={open1 ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick}
            > */}


            <div className={classes1.root} onClick={handleClick} >
                <div
                    id="demo-positioned-button"
                    aria-controls={open1 ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? 'true' : undefined}
                // onClick={handleClick}
                >
                    <div className={classes1.searchIconWrapper}>
                        <SearchIcon />
                    </div>
                </div>
                <InputBase
                    className={classes1.inputBase}
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </div>




            {/* <div className={classess.root}>
             
                <div
                    id="demo-positioned-button"
                    aria-controls={open1 ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <div className={classes.searchIconWrapper}>
                        <SearchIcon />
                    </div>
                </div>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
               
                />
            </div> */}
            {/* </Search> */}
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{ paper: styles.paper }}
                style={{ height: 'auto', padding: '10px', width: { lg: '50%' } }}
            >
                <div className={classes1.searchComp}

                    id="demo-positioned-button"
                    aria-controls={open1 ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open1 ? 'true' : undefined}
                >
                    {/* <SearchIconWrapper> */}
                    <div className={classes1.searchIconWrapper}>
                        <img src={SearchImg} alt="search" width="25px" />
                    </div>
                    {/* </SearchIconWrapper> */}
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => searchChange(e.target.value)}
                        autoFocus={true}
                        value={name}
                        endAdornment={<ClearIcon sx={{ mr: '10px' }} className="cursor" onClick={handleClose} />}
                    />
                </div>
                <Stack direction="row" spacing={1} sx={{ mt: '20px', ml: '10px', flexWrap: 'wrap' }}>
                    <Typography sx={{ mr: '10px' }}>
                        Filter
                    </Typography>
                    <ChipData label="People" handleFilter={(value) => handleFilter(value)} value={key === "people" ? true : false} a={"people"} />
                    <ChipData label="Organisations" handleFilter={(value) => handleFilter(value)} value={key === "organisation" ? true : false} a={'organisation'} />
                    <ChipData label="Topics" handleFilter={(value) => handleFilter(value)} value={key === "hashtags" ? true : false} a={'hashtags'} />
                    {/* <ChipData label="Posts" handleFilter={handleFilter} value={4}/> */}
                    <ChipData label="Events" handleFilter={(value) => handleFilter(value)} value={key === "events" ? true : false} a={'events'} />
                    {/* <ChipData label="Evidence Review" handleFilter={(value)=>handleFilter(value)} value={false} a={'diseases'}/>
                <ChipData label="Drug Information" handleFilter={(value)=>handleFilter(value)} value={false} a={'drugs'}/> */}

                </Stack>
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    {load ? <div style={{ height: '100px' }} className="elib-right__content"><CircularProgress disableShrink size="1.5rem" /></div> :
                        searchOptions.length > 0 ? searchOptions.map((option, i) => (
                            <Box component="li" key={i} sx={{ '& > img': { mr: 2, flexShrink: 0 }, p: '10px' }} className="e-align d-flex cursor" onClick={() => userSelect(option)}>
                                {option.profile_pic || option.type === "people" ? <Avatar style={{ height: "40px", width: "40px", borderRadius: '10px', boxShadow: '0px 3px 6px #00000029' }} src={option.profile_pic ? option.profile_pic : profimage} /> :
                                    <>
                                        <div className="MuiAvatar-root MuiAvatar-circular css-1wlk0hk-MuiAvatar-root" style={{ height: '40px', width: '40px', borderRadius: '10px', boxShadow: '0px 3px 6px #00000029', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {option.type === "organisation" ?
                                                // <img src={Building} alt="org" className='search_pics' />
                                                <div style={{ backgroundColor: '#A8E8FE', width: '55px', height: "55px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "7px" }}>
                                                    <img src={OrgLogo} alt="organisation" className='search_pics' />
                                                </div>
                                                : option.type === "hashtags" ?
                                                    <img src={hashtag_icon} alt="hashtags" className='search_pics' style={{ width: '25px' }} />
                                                    : option.type === "events" ?
                                                        <img src={upcoming} alt="events" className='search_pics' style={{ width: '20px' }} />
                                                        : ''}
                                        </div>
                                    </>}
                                <span style={{ paddingLeft: "10px" }}>
                                    {option.name ? option.name : option.name}
                                </span>
                            </Box>
                        )) : name !== '' ? <Box sx={{ p: '10px' }}>No Results</Box> : ''}
                </div>
            </Menu>
        </div>
    )
}