// import React, { useEffect, useState } from "react";
// import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import { Edit, ExpandMore } from "@mui/icons-material";
// import DialogBox from "../../../../../../DialogBox/dialog";
// import { FormLayout } from "../../../../../../common/components/layout";
// import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';

// import axios from "axios";
// import { useSelector } from "react-redux";
// import { Editor } from 'react-draft-wysiwyg';
// import { ValidatorForm } from "react-material-ui-form-validator";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from 'html-to-draftjs';

// // import e from "cors";

// const FAQ = () => {

//     const healthDeptLoc = useSelector((state) => state.registration.healthDeptLocationDetails);
//     const healthCaree = useSelector((state) => state.registration.healthDeptDetails);


//     const [expanded, setExpanded] = useState(null);
//     const [open, setOpen] = useState(false);
//     const [state, setState] = useState("")
//     const [faqs, setFaqs] = useState([])
//     const [textValue, setTextValue] = useState('');
//     const [editAns, setEditAns] = useState(null)

//     const html = ``
//     const blocksFromHTML = convertFromHTML(html)
//     const content = ContentState.createFromBlockArray(blocksFromHTML)
//     const editorContent = EditorState.createWithContent(content)
//     const [editorState, setEditorState] = useState(editorContent);



//     console.log(healthDeptLoc, "fghjk", healthCaree)
//     useEffect(() => {
//         getFaqs()
//     }, [])

//     const getFaqs = () => {
//         axios.get(process.env.REACT_APP_API_URL_PYI + `/knowledBase/healthcare/${healthCaree?.id}`).then(res => {
//             console.log(res?.data, "checkkkk")
//             let data = res?.data?.map((e, _) => ({
//                 ...e,
//                 answer: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(e?.answer || `<p></p>`)))
//             }))
//             console.log(data, "checkkkw")
//             setFaqs(data)

//         }).catch(err => console.log(err))
//     }
//     const addFAQ = () => {
//         console.log("first")
//         setOpen(prev => !prev)
//     }
//     const handleChange = (panel) => (event, isExpanded) => {
//         setExpanded(isExpanded ? panel : null);
//         setEditAns(null)
//     };

//     const handleChangeAdd = (name, value) => {
//         setState(value)
//     }
//     const handleAdding = (que, length) => {
//         let data = {
//             "resourceType": "healthcare",
//             "identifier": healthCaree?.id,
//             "question": que,
//             "answer": "",
//             "index": length + 1
//         }

//         axios.post(process.env.REACT_APP_API_URL_PYI + `/knowledBase`, data).then(res => {
//             console.log(res)
//             getFaqs()

//         }).catch(err => console.log(err))
//         setOpen(false)
//         setState("")
//     }

//     const editState = (editorState, i) => {
//         setEditorState(editorState)
//         const updatedFaqs = [...faqs];
//         updatedFaqs[i] = {
//             ...updatedFaqs[i],
//             answer: editorState
//         };
//         setFaqs(updatedFaqs);
//         // let datass = draftToHtml(convertToRaw(editorState.getCurrentContent()))
//         console.log("editorState", updatedFaqs)
//     }
//     console.log(editorState, "editorState", faqs)
//     // const handleChangeTextArea = (name, value, i) => {
//     //     const updatedFaqs = [...faqs];
//     //     updatedFaqs[i] = {
//     //         ...updatedFaqs[i],
//     //         answer: value
//     //     };
//     //     console.log(name, value, i, updatedFaqs)
//     //     setFaqs(updatedFaqs);
//     // };
//     console.log(faqs, "fjdkfjdkfjk")
//     const handleUpdate = (e, i) => {
//         console.log(e, i)

//         const updatedFaqs = faqs.map((ea) => {
//             if (ea.id === e.id) {
//                 return {
//                     ...ea,
//                     answer: draftToHtml(convertToRaw(ea?.answer.getCurrentContent()))
//                 };
//             }
//             return ea;
//         });

//         console.log(updatedFaqs[i],)
//         axios.put(process.env.REACT_APP_API_URL_PYI + `/knowledBaseupdate/${e?.id}`, updatedFaqs[i]).then(res => {
//             console.log(res, "checkkkk")
//             getFaqs()
//             setExpanded(null)
//         }).catch(err => console.log(err))
//     }
//     return (
//         <div className="mv1">
//             {/* <DialogBox open={open} handleDialog={() => setOpen(false)}
//                 Title={"faq"} onClose={() => setOpen(false)}
//                 Content={<>

//                     <FormLayout name="question" value={state} handleChange={handleChangeAdd} placeholder="Question" required errorMessages={['Enter registration number']} />
//                 </>}
//                 Actions={<div><Button variant="contained" sx={{ mr: 2, borderRadius: 5, textTransform: "capitalize", height: "30px" }}>cancel</Button>
//                     <Button variant="contained" sx={{ borderRadius: 5, textTransform: "capitalize", height: "30px" }} onClick={() => handleAdding(state, faqs?.length)}>submit</Button></div>}
//             /> */}

//             <Dialog maxWidth={"sm"} fullWidth={true} open={open} onClose={() => setOpen(false)}
//                 sx={{
//                     '.MuiDialogTitle-root': {
//                         padding: '9px 15px'
//                     }
//                 }}>
//                 <DialogTitle> Faq </DialogTitle>
//                 <ValidatorForm onSubmit={() => handleAdding(state, faqs?.length)}>
//                     <DialogContent sx={{
//                         padding: '12px 24px'
//                     }}>
//                         <FormLayout name="question" value={state} handleChange={handleChangeAdd} placeholder="Question" required errorMessages={['Enter your question']} />
//                         <DialogActions sx={{ mt: 1 }}>
//                             <Button variant="contained" sx={{ mr: 2, borderRadius: 5, textTransform: "capitalize", height: "30px" }} onClick={() => setOpen(false)}>cancel</Button>
//                             <Button variant="contained" sx={{ borderRadius: 5, textTransform: "capitalize", height: "30px" }}
//                                 type="submit"
//                             // onClick={() => handleAdding(state, faqs?.length)}
//                             >submit</Button>
//                         </DialogActions>
//                     </DialogContent>
//                 </ValidatorForm>
//             </Dialog>


//             <Card sx={{ padding: "10px", minHeight: "150px", }}>
//                 <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
//                     <Grid item>
//                         <div className="font-head fntSemiBold">
//                             Frequently Asked Question
//                         </div>
//                     </Grid>
//                     <Grid item>
//                         <div>
//                             <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} onClick={addFAQ} className="cursor" />
//                         </div>

//                     </Grid>
//                 </Grid>

//                 {faqs?.length > 0 &&
//                     faqs.map((each, i) => {
//                         return (
//                             <Accordion expanded={expanded === i} onChange={handleChange(i)} sx={{ '.MuiButtonBase-root': { padding: '2px 10px !important' } }}>
//                                 <AccordionSummary
//                                     expandIcon={<ExpandMore />}
//                                     aria-controls="panel1bh-content"
//                                     id="panel1bh-header"
//                                 >
//                                     <Typography sx={{ width: '33%', flexShrink: 0, textTransform: 'capitalize' }}>
//                                         {each?.question}
//                                     </Typography>
//                                 </AccordionSummary>
//                                 <AccordionDetails>
//                                     <Grid>
//                                         {console.log(each, "7878005")}
//                                         {
//                                             each?.id !== editAns ?
//                                                 <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                                                     <div>
//                                                         {each?.answer || "write your answer"}
//                                                     </div>
//                                                     <Edit onClick={() => setEditAns(each?.id)} />
//                                                 </Grid>

//                                                 :
//                                                 <>
//                                                     {/* <textarea
//                                                         name="answer"
//                                                         value={each.answer}
//                                                         // value={textValue}
//                                                         // onChange={e => setTextValue(e.target.value)}
//                                                         onChange={(e) => handleChangeTextArea('answer', e.target.value, i)}
//                                                         placeholder="Enter your text here..."
//                                                         style={{ width: '98%', height: '160px', padding: '10px' }}
//                                                     /> */}

//                                                     <Editor
//                                                         editorStyle={{
//                                                             textOverflow: 'hidden',
//                                                             height: '250px',
//                                                         }}
//                                                         editorState={each.answer}
//                                                         toolbarClassName="toolbarClassName"
//                                                         wrapperClassName="wrapperClassName"
//                                                         editorClassName="editorClassName"
//                                                         onEditorStateChange={(editorState) => editState(editorState, i)}
//                                                     />




//                                                     <Button sx={{ float: 'right', m: 1 }} size="small" variant="contained" className="capitalize" onClick={() => handleUpdate(each, i)}>update</Button>
//                                                 </>
//                                         }
//                                     </Grid>
//                                 </AccordionDetails>
//                             </Accordion>
//                         )
//                     })}



//             </Card>
//         </div>
//     )
// }
// export default FAQ;

import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import { FormLayout } from "../../../../../../common/components/layout";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import axios from "axios";
import { useSelector } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import { ValidatorForm } from "react-material-ui-form-validator";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

const FAQ = ({ healthCare }) => {


    const healthCaree = healthCare;
    console.log("healthServiceLoc", healthCaree?.id)

    const [expanded, setExpanded] = useState(null);
    const [open, setOpen] = useState(false);
    const [state, setState] = useState("");
    const [faqs, setFaqs] = useState([]);
    const [textValue, setTextValue] = useState('');
    const [editAns, setEditAns] = useState(null);

    const html = ``;
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(blocksFromHTML);
    const editorContent = EditorState.createWithContent(content);
    const [editorState, setEditorState] = useState(editorContent);


    useEffect(() => {
        getFaqs();
    }, []);

    const getFaqs = () => {
        axios.get(`${process.env.REACT_APP_API_URL_PYI}/knowledBase/healthcare/${healthCaree?.id}`)
            .then(res => {
                console.log(res?.data, "checkkkk");
                let data = res?.data?.map((e, _) => ({
                    ...e,
                    answer: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(e?.answer || `<p></p>`).contentBlocks))
                }));
                console.log(data, "checkkkw");
                setFaqs(data);
            })
            .catch(err => console.log(err));
    };

    const addFAQ = () => {
        console.log("first");
        setOpen(prev => !prev);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
        setEditAns(null);
    };

    const handleChangeAdd = (name, value) => {
        setState(value);
    };

    const handleAdding = (que, length) => {
        let data = {
            "resourceType": "healthcare",
            "identifier": healthCaree?.id,
            "question": que,
            "answer": "",
            "index": length + 1
        };

        axios.post(`${process.env.REACT_APP_API_URL_PYI}/knowledBase`, data)
            .then(res => {
                console.log(res);
                getFaqs();
            })
            .catch(err => console.log(err));
        setOpen(false);
        setState("");
    };

    const editState = (editorState, i) => {
        setEditorState(editorState);
        const updatedFaqs = [...faqs];
        updatedFaqs[i] = {
            ...updatedFaqs[i],
            answer: editorState
        };
        setFaqs(updatedFaqs);
        console.log("editorState", updatedFaqs);
    };

    const handleDeleteFAQ = (item, i) => {
        console.log(item, i)
        axios.delete(`${process.env.REACT_APP_API_URL_PYI}/deleteknowledBase/${item?.id}`)
            .then(res => {
                console.log(res)
                getFaqs();
            })
            .catch(err => console.log(err))

    }
    console.log(faqs, "fjdkfjdkfjk");

    const handleUpdate = (e, i) => {
        console.log(e, i);

        const updatedFaqs = faqs?.map((ea) => {
            if (ea.id === e.id) {
                return {
                    ...ea,
                    answer: draftToHtml(convertToRaw(ea?.answer.getCurrentContent()))
                };
            }
            return ea;
        });

        console.log(updatedFaqs[i]);
        axios.put(`${process.env.REACT_APP_API_URL_PYI}/knowledBaseupdate/${e?.id}`, updatedFaqs[i])
            .then(res => {
                console.log(res, "checkkkk");
                getFaqs();
                setExpanded(null);
            })
            .catch(err => console.log(err));
    };


    return (
        <div className="mt2">
            <Dialog
                maxWidth={"sm"}
                fullWidth={true}
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    '.MuiDialogTitle-root': {
                        padding: '9px 15px'
                    }
                }}
            >
                <DialogTitle>Faq</DialogTitle>
                <ValidatorForm onSubmit={() => handleAdding(state, faqs?.length)}>
                    <DialogContent sx={{ padding: '12px 24px' }}>
                        <FormLayout
                            name="question"
                            value={state}
                            handleChange={handleChangeAdd}
                            placeholder="Question"
                            required
                            errorMessages={['Enter your question']}
                        />
                        <DialogActions sx={{ mt: 1 }}>
                            <Button
                                variant="contained"
                                sx={{ mr: 2, borderRadius: 5, textTransform: "capitalize", height: "30px" }}
                                onClick={() => setOpen(false)}
                            >
                                cancel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: 5, textTransform: "capitalize", height: "30px" }}
                                type="submit"
                            >
                                submit
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </ValidatorForm>
            </Dialog>

            <Card sx={{ padding: "10px", minHeight: "150px", boxShadow: "0px 0px 1.6px #888" }}>
                <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                    <Grid item>
                        <div>

                            <Typography className="capitalize" sx={{
                                fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                "&.MuiTypography-root": {
                                    lineswhiteSpace: 0,
                                    fontFamily: "unset"
                                }

                            }}>Frequently Asked Question</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <AddCircleIcon fontSize="small" sx={{ color: "#008114" }} onClick={addFAQ} className="cursor" />
                        </div>
                    </Grid>
                </Grid>

                {faqs?.length > 0 && faqs?.map((each, i) => (
                    <Accordion
                        key={i}
                        expanded={expanded === i}
                        onChange={handleChange(i)}
                        sx={{ '.MuiButtonBase-root': { padding: '2px 10px !important' } }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, textTransform: 'capitalize' }}>
                                {each?.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid>
                                {console.log(each, "7878005")}
                                {each?.id !== editAns ? (
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            {each?.answer && each.answer?.getCurrentContent()?.hasText() ? (
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(each.answer.getCurrentContent())) }}
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                />
                                            ) : "write your answer"}
                                        </div>
                                        <div>

                                            <Edit sx={{ marginLeft: '4px', cursor: 'pointer' }} onClick={() => setEditAns(each?.id)} />
                                            <Delete sx={{ marginLeft: '4px', cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFAQ(each, i)} />
                                        </div>
                                    </Grid>
                                ) : (
                                    <>
                                        <Editor
                                            editorStyle={{ height: '250px' }}
                                            editorState={each.answer}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={(editorState) => editState(editorState, i)}
                                        />
                                        <Button
                                            sx={{ float: 'right', m: 1 }}
                                            size="small"
                                            variant="contained"
                                            className="capitalize"
                                            onClick={() => handleUpdate(each, i)}
                                        >
                                            update
                                        </Button>
                                    </>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Card>
        </div>
    );
};

export default FAQ;
