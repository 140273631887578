import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { common } from "@mui/material/colors";
import TextField from "../../../Fields/TextFields";
import { ValidatorForm } from "react-material-ui-form-validator";
import CssTextField from "../../../Fields/CssTextField";
import Plus from "../../../../images/plus.svg";
import Status from "../../../../images/status.svg";
import Delete from "../../../../images/delete.svg";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import MuiPhoneNumber from "material-ui-phone-number";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DialogActions, IconButton } from "@mui/material";
import "../myprofile.css";
import axios from "axios";
import UserService from "../../../../services/UserService";
import URL from "../../../apiurl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { invalid } from "moment";
import CloseIcon from "@mui/icons-material/Close";

// import Snackbar from "@mui/material/Snackbar";
import Snackbar from "@mui/material/Snackbar";
import { DialogBox as DialogEmail } from "../../../common/components/dialogs";
import {
  Actions,
  FormDivider,
  FormLayout,
} from "../../../common/components/layout";
import AutocompleteForm from "../../../calculators/forminputs/Autocomplete";
import DateIn from "../../../common/inputs/dateInput";
import CustomAutocomplete from "../../../common/inputs/Autocomplete";
import FreeSoloAutomplete from "../../../common/inputs/FreeSoloCustomField";
import {
  D_API,
  D_API_FHIR,
  DOCS_WITHOUT_TOKEN,
} from "../../../../services/APIService";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import dayjs from "dayjs";
// import { FormDivider } from "../../../utility/utils";

const systemsData = [
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Nurse-Courses",
    display: "Nurse-Councils",
  },
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Modern-Medicine",
    display: "Modern-Medicine",
  },
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Dentistry",
    display: "Dentistry",
  },
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Ayurveda",
    display: "Ayurveda",
  },
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Unani",
    display: "Unani",
  },
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Siddha",
    display: "Siddha",
  },
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Sowa-Rigpa",
    display: "Sowa-Rigpa",
  },
  {
    system:
      "https://sandbox.abdm.gov.in/sandbox/Master_Data/doctor-degrees/Homeopathy",
    display: "Homeopathy",
  },
];
const systemsData2 = [
  {
    display: "Nurse-Councils",
    system: [{ url: "https://hpr.abdm.gov.in/apis/v1/masters/Nurse-Councils" }],
  },
  {
    display: "Modern-Medicine",
    system: [
      { url: "https://hpr.abdm.gov.in/apis/v1/masters/Modern-Medicine" },
    ],
  },
  {
    display: "Dentistry",
    system: [{ url: "https://hpr.abdm.gov.in/apis/v1/masters/Dentistry" }],
  },
  {
    display: "Ayurveda",
    system: [{ url: "https://hpr.abdm.gov.in/apis/v1/masters/Ayurveda" }],
  },
];
const bscode = [
  "BAMS - Bachelor of Ayurvedic Medicine and Surgery",
  "BDS - Bachelor of Dental Surgery",
  "BHMS - Bachelor of Homeopathy Medicine and Surgery",
  "BUMS - Bachelor of Unani Medicine and Surgery",
  "BYNS- Bachelor of Yoga and Naturopathy Sciences",
  "MBBS – Bachelor of Medicine, Bachelor of Surgery",
];

const courseList = [
  "Anaesthesiology",
  "Anaesthesiology, Pain Medicine and Critical Care",
  "Anatomy",
  "BAMS - Bachelor of Ayurvedic Medicine and Surgery",
  "BDS - Bachelor of Dental Surgery",
  "BHMS - Bachelor of Homeopathy Medicine and Surgery",
  "Biochemistry",
  "BUMS - Bachelor of Unani Medicine and Surgery",
  "BYNS- Bachelor of Yoga and Naturopathy Sciences",
  "Cardiac-Anaesthesiology",
  "Cardiac-Radiology",
  "Cardiology",
  "Cardiothoracic and Vascular Surgery",
  "Community Health",
  "Dermatology",
  "Dermatology, Venerology and Leprosy",
  "Ear, Nose and Throat",
  "Emergency and Critical care",
  "Emergency Medicine",
  "Endocrinology",
  "ENT",
  "Family Medicine",
  "Field Epidemiology",
  "Forensic Medicine",
  "Gastroenterology",
  "Gastrointestinal Surgery",
  "General Medicine",
  "General Surgery",
  "Haematology",
  "Health Administration",
  "Immunohematology and transfusion medicine",
  "Maternal and Child Health",
  "MBBS – Bachelor of Medicine, Bachelor of Surgery",
  "Medicine and Microbiology",
  "Microbiology",
  "Nephrology",
  "Neuro Surgery",
  "Neuro-Anaesthesiology and Critical Care",
  "Neurology",
  "Nuclear Medicine",
  "Obstetrics and Gynaecology",
  "Onco-Anesthesiology and Palliative Medicine",
  "Ophthalmology",
  "Orthopaedic Surgery",
  "Orthopaedics",
  "Oto-Rhino Laryngology",
  "Paediatrics",
  "Palliative Medicine",
  "Pathology",
  "Pediatric Surgery",
  "Pharmacology",
  "Physical Medicine and Rehabilitation",
  "Physiology",
  "Plastic and Reconstructive Surgery",
  "Preventive and Social Medicine",
  "Psychiatry",
  "Pulmonary and Sleep disorders",
  "Radio-Diagnosis",
  "Radio-Therapy",
  "Respiratory diseases",
  "Rural Surgery",
  "Skin and Venereal diseases",
  "Social and Preventive Medicine",
  "Surgery",
  "Surgery Trauma Centre",
  "Surgical Oncology",
  "Transfusion Medicine",
  "Tropical Medicine",
  "Tuberculosis and Respiratory diseases",
  "Urology",
];
export const medicalcounsil = [
  "National Medical Commission(erstwhile Medical Council of India)",
  "Andhra Pradesh Medical Council",
  "Arunachal Pradesh Medical Council",
  "Bihar Medical Council",
  "Chhattisgarh Medical Council",
  "Delhi Medical Council",
  "Goa Medical Council",
  "Gujarat Medical Council",
  "Haryana Medical Council",
  "Himanchal Pradesh Medical Council",
  "Jammu and  Kashmir Medical Council",
  "Jharkhand Medical Council",
  "Karnataka Medical Council",
  "Madhya Pradesh Medical Council(Bhopal Medical Council / Mahakaushal Medical Council)",
  "Maharashtra Medical Council",
  "Manipur Medical Council",
  "Mizoram Medical Council",
  "Nagaland Medical Council",
  "Orissa Council of Medical Registration",
  " Punjab Medical Council ",
  "Rajasthan Medical Council",
  "Sikkim Medical Council ",
  "Tamil Nadu Medical Council",
  "Telangana State Medical Council",
  "Kerala State Medical Councils",
  "Tripura State Medical Council",
  "Uttarakhand Medical Council ",
  "Uttar Pradesh Medical Council",
  " West Bengal Medical Council ",
  "State Medical Council, Meghalaya ",
  "Assam Medical Council",
  "CHD MEDICAL COUNCIL",
];
const filter = createFilterOptions();

const useStyles = makeStyles({
  container: {
    alignItems: "flex-start",
  },
  paper: {
    marginTop: "65px",
  },
});

export const getAPI = async (api) => {
  let data = axios
    .get(api, {
      headers: {
        Authorization: `Bearer ${UserService.getToken()}`,
      },
    })
    .then((res) => {
      return res.data.data;
    });
  return data;
};

const RadioB = ({ label, value }) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            color: common.black,
            "&.Mui-checked": {
              color: common.black,
            },
            // '& .MuiSvgIcon-root': { fontSize: '17px' }
          }}
        />
      }
      label={label}
    />
  );
};

export const allCountries = getAPI(`${URL.elib}all_countries`);

export default function Update({
  open,
  data,
  address,
  languageValue,
  con,
  handleDialog,
  handleDelete,
  handleUser,
  handleSubmit,
  handleVisibility,
  sendEmailOTP,
  sendOTP,
  charan,
  hprInfo,
}) {
  console.log(data, "5000", languageValue, charan, address);
  const quaData = {
    degree: null,
    course: null,
    doc: "",
    orgName: "",
    issuer: "",
    identifier: "",
    dateIss: null,
  };
  const licenc = { council: "", regNo: "", date: null, dueDate: null, doc: "" };

  // console.log(address)
  const styles = useStyles();
  const [contacts, setContacts] = React.useState([]);
  const [system, setSystem] = React.useState(["email", "phone"]);
  // const [use, setUse] = React.useState(['work', 'home'])
  const [use, setUse] = React.useState(["home"]);
  const [countries, setCountries] = React.useState([]);
  const [loc, setLoc] = React.useState({
    country_name: address.country,
    state_name: address.state,
    city_name: address.city,
  });
  const [addressList, setAddressList] = useState(address);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [suffixes, setSuffixes] = React.useState(["MBBS", "MD"]);
  const [form, setForm] = useState(data);
  // const [opened, setOpened] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [opendeleteEmailAlert, setOpendeleteEmailAlert] = useState(false);
  const [particularIdx, setParticularIdx] = useState("");
  const [edu, setEdu] = useState([{ ...quaData }]);
  const [licence, setLicence] = useState([{ ...licenc }]);
  const [courses, setCourses] = useState([]);
  const [degree, setDegree] = useState([]);
  const [degreeType, setDegreeType] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [UsersDatas, setUsersDatas] = useState();
  const [languagesList, setLanguagesList] = useState([]);

  const state = useSelector((state) => state.userProfile.userProfile);
  console.log(state, "state");
  console.log(form, "formm");

  // const ident = state?.practioner?.identifier.filter(e => e.system === "https://healthid.ndhm.gov.in/").map(e => e.value)[0];
  const ident =
    state?.practioner?.identifier?.length > 0
      ? state.practioner.identifier
        .filter((e) => e.system === "https://healthid.ndhm.gov.in/")
        .map((e) => e.value)[0]
      : [];

  // console.log(ident, "78787878hjjnj")
  React.useEffect(() => {
    let data = [];
    if (JSON.stringify(con) !== JSON.stringify(contacts)) {
      con.map((contact) =>
        Object.keys(contact).length === 5
          ? data.push({ ...contact })
          : contact.system === "phone"
            ? data.push({
              ...contact,
              public: contact.public || "SHOW",
              verify: contact.verify || true,
            })
            : data.push({
              ...contact,
              public: contact.public || "SHOW",
              verify: contact.verify || false,
            })
      );
      setContacts(data);
    }
  }, [con]);
  // console.log(form, "formform")

  useEffect(() => {
    axios
      .get("https://devfhir.docsteth.com/fhir-server/api/v4/ValueSet/languages")
      .then((res) => {
        // console.log(res, "res1")
        // return res.data.compose?.include?.[0]?.concept;
        setLanguages(res?.data?.compose?.include?.[0]?.concept);
      });
  }, []);

  // 4
  useEffect(() => {
    DOCS_WITHOUT_TOKEN.get(
      `Practitioner/identifier/new/dev.docsteth.com/${UserService.getUserId()}`
    )
      .then((res) => {
        // console.log(res)
        setUsersDatas(res?.data?.entry?.[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  // console.log(UsersDatas, "userDatass")
  useEffect(() => {
    // console.log(data?.qualification, "eeeee11eeeeeeee11")
    // data?.qualification?.map(e => e.identifier?.[0]?.type?.code === "LN" ?

    //   setLicence([{
    //     council: e?.issuer?.display,
    //     regNo: e?.identifier?.[0]?.value,
    //     date: e?.period?.start,
    //     dueDate: e?.period?.end,
    //     doc: ''
    //   }])
    //   : setEdu([{
    //     degree: {
    //       "system": e?.code?.coding?.[0]?.system,
    //       "display": e?.code?.text
    //     },
    //     course: e?.code?.coding?.[0],
    //     doc: '',
    //     orgName: 'rtyu',
    //     issuer: {
    //       "name": e?.issuer?.display,
    //       "id": e?.issuer?.reference?.split('/')[1],

    //     },
    //     dateIss: e?.period?.end,
    //     identifier: e?.identifier?.[0]?.value
    //   }]))

    if (data?.qualification) {
      console.log(data.qualification, "in userupdate");
      const newLicences = [];
      const newEducation = [];

      data.qualification.forEach((e) => {
        const identifierType = e?.identifier?.[0]?.type?.coding?.[0]?.code;
        if (identifierType === "LN") {
          newLicences.push({
            council: e?.issuer?.display?.trim(),
            regNo: e?.identifier?.[0]?.value,
            date: e?.period?.start,
            dueDate: e?.period?.end,
            doc: "",
          });
        } else {
          newEducation.push({
            degree: {
              system: e?.code?.coding?.[0]?.system,
              display: e?.code?.text,
            },
            course: e?.code?.coding?.[0],
            doc: "",
            orgName: "rtyu",
            issuer: {
              name: e?.issuer?.display?.trim(),
              id: e?.issuer?.reference?.split("/")[1],
              code: e?.issuer?.code,
            },
            dateIss: e?.period?.end,
            identifier: e?.identifier?.[0]?.value,
          });
        }
      });

      console.log(newLicences, "too1", newEducation);

      setLicence(newLicences);
      setEdu(newEducation);
    }
  }, [data]);

  console.log(licence, "too", edu);
  // console.log(edu, "eeeee11eeeeeee", licence)

  // useEffect(() => {
  //   setForm((prev) => {
  //     return {
  //       ...prev, languages: state?.communication ? state?.communication?.map((e) => e) : [],
  //     }
  //   })

  // }, [state])

  useEffect(() => {
    D_API_FHIR.get(`ValueSet?name=DoctorDegrees`)
      .then((res) => {
        setDegree(res?.data?.entry?.[0]?.resource?.compose?.include || []);
      })
      .catch((err) => console.log(err, "hihihihii1"));
  }, []);
  // console.log(degree, "matched")

  useEffect(() => {
    getCountries();

    let suffix = [...suffixes];
    data?.suffix?.every((i) => (!suffix?.includes(i) ? suffix?.push(i) : i));
    setSuffixes(suffix);
  }, []);

  const getCountries = async () => {
    let country = await allCountries;
    setCountries(country);
    if (loc.country_name) {
      let code = country.filter((country) =>
        country.name == loc.country_name.name ||
          country.name == loc.country_name
          ? country
          : ""
      );

      if (code.length > 0) {
        getStates(code[0].iso2, loc.state_name);
        setLoc({ ...loc, country_name: code[0] });
      } else {
        setStates([]);
        setCities([]);
      }
    }
  };

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_CLINIDESK +
        `getStepsByUserId/${UserService.getUserId()}`
      )
      .then((res) => {
        // console.log(res, "89898989898")
        // setStepUpdate(res?.data)
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   D_API_FHIR.get(`ValueSet?name=CommonLanguages`).then(res => {
  //     setLanguages(res?.data?.entry?.[0]?.resource?.compose?.include?.[0]?.concept?.map(({ code, display }) => ({ code, display })) || [])
  //   })
  // }, [])

  useEffect(() => {
    // const matchingSystem = degree.map(e => e?.system === edu?.[0]?.degree?.system);
    // if (matchingSystem) {
    //   console.log("Matched system:", matchingSystem);
    // } else {
    //   console.log("No Matched system found.");
    // }
    // const matchingSystems = degree.filter(each => each?.system === edu?.[0]?.degree?.system);

    const matchingSystems = degree.filter((each) =>
      edu.some((ea) => ea?.degree?.system === each?.system)
    );
    console.log(degree, matchingSystems, 438);
    if (matchingSystems.length > 0) {
      setDegreeType(matchingSystems || []);
    } else {
      console.log("No matched system found.");
      setDegreeType([]);
    }
  }, [edu]);
  const getStates = async (country, statename) => {
    let states = await getAPI(`${URL.elib}country_wise_states/${country}`);
    setStates(states);
    if (statename) {
      let code = states.filter((state) =>
        state.name == statename ? state : ""
      );
      if (code.length > 0) {
        getCities(country, code[0].iso2);
      } else {
        setCities([]);
      }
    }
  };

  const handleEmailDialog = (i) => {
    setParticularIdx(i);
    setOpendeleteEmailAlert(true);
  };

  const handleCloseEmailDialog = () => {
    setOpendeleteEmailAlert(false);
    setParticularIdx("");
  };
  const handleAddress = (name, value) => {
    setAddressList({ ...addressList, [name]: value });
  };

  const getCities = async (country, state) => {
    let cities = await getAPI(
      `${URL.elib}state_wise_cities/${country}-${state}`
    );
    setCities(cities);
  };

  const getStateData = (val) => {
    setStates([]);
    setCities([]);
    if (val) {
      handleAddress("country", val.name);
      getStates(val.iso2);
    } else {
      setAddressList({ ...addressList, country: "", state: "", city: "" });
    }
    setLoc({ country_name: val, state_name: null, city_name: null });
  };

  const getCityData = (val) => {
    setCities([]);
    if (val) {
      handleAddress("state", val.name);
      setLoc({ ...loc, state_name: val.name, city_name: null });
      getCities(loc.country_name.iso2, val.iso2);
    } else {
      setAddressList({ ...addressList, state: "", city: "" });
      setLoc({ ...loc, state_name: null, city_name: null });
      setCities([]);
    }
  };

  const citySelect = (val) => {
    if (val) {
      handleAddress("city", val.name);
      setLoc({ ...loc, city_name: val });
    } else {
      handleAddress("city", "");
      setLoc({ ...loc, city_name: null });
    }
  };

  const handlePublic = (name, value, index) => {
    let length = contacts.length;
    if (
      contacts[length - 1].system !== "" &&
      contacts[length - 1].use !== "" &&
      contacts[length - 1].value !== ""
    ) {
      let data = [...contacts];
      data[index][name] = value === "SHOW" ? "HIDE" : "SHOW";
      setContacts(data);
      handleVisibility(data);
    } else {
      alert("Please fill the empty fields");
    }
  };

  const handleContactChange = (name, value, index) => {
    const con = [...contacts];
    con[index][name] = value;
    setContacts(con);
  };

  const handleForm = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleAdd = () => {
    let length = contacts.length;
    if (contacts.length === 0) {
      setContacts([
        ...contacts,
        { system: "", use: "", value: "", public: "SHOW", verify: false },
      ]);
    } else if (
      contacts[length - 1].system !== "" &&
      contacts[length - 1].use !== "" &&
      contacts[length - 1].value !== ""
    ) {
      setContacts([
        ...contacts,
        { system: "", use: "", value: "", public: "SHOW", verify: false },
      ]);
    } else {
      alert("Please fill the empty fields");
    }
  };

  const handleAddEducation = () => {
    setEdu([...edu, quaData]);
  };

  const handleAddLicenc = () => {
    setLicence([...licence, licenc]);
  };

  const handleClick = () => { };
  const handleClose = () => {
    // if (reason === "clickaway") {
    //   return;
    // }

    setOpened(false);
  };

  // const handleClick = () => {
  // //   setOpened(true);
  // };

  //  const handleClose = () => {
  //    // if (reason === "clickaway") {
  //    //   return;
  //    // }

  //    setOpened(false);
  //  };

  const handleQua = (i) => (name, value) => {
    let data = [...edu];
    data[i] = { ...data[i], [name]: value };

    if (name === "degree" && value !== null) {
      data[i] = { ...data[i], course: "" };
      if (value.display === "Bachelor of Science (Medical)") {
        setCourses(bscode);
      } else {
        setCourses(courseList);
      }
    }

    setEdu(data);
  };

  console.log(edu, "handle");

  const handleChange = (i) => (name, value) => {
    let licenceData = [...licence];
    licenceData[i] = { ...licenceData[i], [name]: value };
    setLicence(licenceData);
  };

  const deleteLicence = (indx) => {
    let newList = [...licence];
    newList = newList.filter((e, i) => i != indx);
    setLicence(newList);
  };

  const deleteEducation = (indx) => {
    let newList = [...edu];
    newList = newList.filter((e, i) => i != indx);
    setEdu(newList);
  };

  return (
    <>
      <ValidatorForm
        onSubmit={() => {
          // if (
          //   form.birthDate !== "Invalid Date" &&
          //   form.birthDate !== null
          // ) {
          //   handleSubmit(form, contacts, addressList, edu, licence, UsersDatas, ident);
          //   setOpened(true);
          // }
        }}
      >
        <Dialog
          open={open}
          fullWidth
          maxWidth={"lg"}
          // scroll='paper'
          // aria-labelledby="scroll-dialog-title"
          // aria-describedby="scroll-dialog-description"
          sx={{ mb: 2, alignItems: "flex-start" }}
          classes={{ container: styles.container, paper: styles.paper }}
        >
          <DialogTitle
            sx={{
              borderBottom: "1px solid #707070",
              color: "#B300F5",
              fontWeight: "bold",
              fontSize: "21px",
            }}
          // id="scroll-dialog-title"
          >
            Profile
          </DialogTitle>

          <DialogContent
            className="user_update_main"
            sx={{ mb: 3, height: 440 }}
          >
            <div>
              <Grid container sx={{ mt: 2 }} spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <p>Prefix</p>
                  <FormControl
                    component="fieldset"
                    style={{ marginLeft: "24px" }}
                  >
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      value={form.prefix}
                      onChange={(e, value) => handleForm("prefix", value)}
                    >
                      <RadioB label="Dr" value="Dr" />
                      <RadioB label="Mr" value="Mr" />
                      <RadioB label="Ms" value="Ms" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12} style={{ padding: "0px 20px" }}>
                  <TextField
                    label="First Name"
                    placeholder="Enter First Name"
                    value={form.firstName}
                    name="firstName"
                    handleChange={handleForm}
                    validators={["required"]}
                    errorMessages={["Please Enter your First Name"]}
                  />

                  <TextField
                    label="Last Name"
                    placeholder="Enter Last Name"
                    value={form.family}
                    name="family"
                    handleChange={handleForm}
                    validators={["required"]}
                    errorMessages={["Please Enter your Last Name"]}
                  />

                  <TextField
                    label="Display Name"
                    placeholder="Enter Display Name"
                    value={form.text}
                    name="text"
                    handleChange={handleForm}
                    validators={["required"]}
                    errorMessages={["Please Enter your name"]}
                  />
                </Grid>
                <Grid item sm={6} xs={12} style={{ padding: "0px 20px" }}>
                  <TextField
                    label="Middle Name"
                    placeholder="Enter Middle Name"
                    value={form.middleName ? form.middleName : ""}
                    name="middleName"
                    handleChange={handleForm}
                  />

                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push(`${params.inputValue}`);
                      }

                      return filtered;
                    }}
                    disablePortal
                    // id="combo-box-demo"
                    options={suffixes}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) =>
                      option === value || ""
                    }
                    onChange={(event, newValue) => {
                      handleForm("suffix", newValue);
                    }}
                    value={form.suffix}
                    fullWidth
                    renderInput={(params) => (
                      <CssTextField
                        style={{ marginTop: "17px", height: "56px" }}
                        {...params}
                        label="Suffix"
                      />
                    )}
                  />

                  <TextField
                    label="Tagline"
                    placeholder="Your Tagline"
                    value={form.caption}
                    name="caption"
                    handleChange={handleForm}
                  />
                </Grid>
                <Grid xs={12} item style={{ padding: "15px 0px" }}>
                  <p
                    style={{
                      fontSize: "21px",
                      borderBottom: "1px solid #707070",
                      padding: "10px 16px",
                    }}
                  >
                    Personal Information
                  </p>
                </Grid>

                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ padding: "8px 12px", marginTop: "17px" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      className="input_shadow input_padding"
                      label="Date of Birth"
                      value={dayjs(form.birthDate)}
                      onChange={(value) => {
                        handleForm("birthDate", new Date(value));
                      }}
                      // inputFormat="MM/DD/YYYY"
                      format="DD/MM/YYYY"
                      views={["day", "month", "year"]}
                      disableFuture
                      // onError={(value) => { return !!value }}

                      renderInput={(params) => (
                        <CssTextField
                          fullWidth
                          // InputLabelProps={{
                          // shrink: true,
                          // }}
                          // onFocus={()=>setShrink(true)}
                          // onBlur={()=>setShrink(!shrink)}
                          // validators={['required']}
                          // errorMessages={['Please Enter DOB']}
                          // value={form.birthDate}
                          {...params}
                          // value={form.birthDate}
                          value={params.inputProps.value}
                          validators={["required"]}
                          errorMessages={["Please Select Date of Birth"]}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item sm={6} xs={12} style={{ padding: "0px 20px" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={["male", "female"]}
                    getOptionLabel={(option) =>
                      option.charAt(0).toUpperCase() + option.substring(1)
                    }
                    onChange={(event, newValue) => {
                      handleForm("gender", newValue);
                    }}
                    value={form.gender}
                    fullWidth
                    renderInput={(params) => (
                      <CssTextField
                        style={{ marginTop: "16px" }}
                        {...params}
                        label="Gender"
                        validators={["required"]}
                        errorMessages={["Please Select gender"]}
                        value={form.gender}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={12} xs={12} style={{ padding: "0px 14px" }}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push(`${params.inputValue}`);
                      }

                      return filtered;
                    }}
                    disablePortal
                    options={languages}
                    getOptionLabel={(option) => option.display}
                    isOptionEqualToValue={(option, value) =>
                      option === value || ""
                    }
                    onChange={(event, newValue) => {
                      handleForm("languages", newValue);
                    }}
                    value={form.languages}
                    fullWidth
                    renderInput={(params) => (
                      <CssTextField
                        style={{ marginTop: "17px", height: "56px" }}
                        {...params}
                        label="Choose Languages"
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} item style={{ padding: "15px 0px" }}>
                  <p className="p-heading">Address</p>
                </Grid>

                <Grid sm={6} xs={6} item style={{ padding: "0px 20px" }}>
                  <TextField
                    label="Address Line1 "
                    placeholder="Enter your Address Line1"
                    value={addressList.line1}
                    name="line1"
                    handleChange={handleAddress}
                  />
                </Grid>
                <Grid sm={6} xs={6} item style={{ padding: "0px 20px" }}>
                  <TextField
                    label="Address Line2 "
                    placeholder="Enter your Address Line2"
                    value={addressList.line2}
                    name="line2"
                    handleChange={handleAddress}
                  />
                </Grid>

                <Grid item sm={6} xs={6} style={{ padding: "16px 20px" }}>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    getOptionLabel={(option) => option.name || option || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.name == value.name || option.name == value || ""
                    }
                    onChange={(event, newValue) => {
                      getStateData(newValue);
                    }}
                    value={loc.country_name}
                    sx={{
                      mb: 1,
                      width: "100%",
                      mx: "auto",
                    }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        value={params.inputProps.value}
                        label="Select country"
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={6} style={{ padding: "16px 20px" }}>
                  <Autocomplete
                    disablePortal
                    id="state"
                    options={states}
                    getOptionLabel={(option) => option.name || option || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.name == value.name || option.name == value || ""
                    }
                    onChange={(event, newValue) => {
                      getCityData(newValue);
                    }}
                    value={loc.state_name}
                    sx={{
                      mb: 1,
                      width: "100%",
                      mx: "auto",
                    }}
                    renderInput={(params) => (
                      <CssTextField {...params} label="Select State" />
                    )}
                  />
                </Grid>

                <Grid item sm={6} xs={6} style={{ padding: "0px 20px" }}>
                  <Autocomplete
                    disablePortal
                    id="city"
                    options={cities}
                    getOptionLabel={(option) => option.name || option || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.name == value.name || option.name == value || ""
                    }
                    onChange={(event, newValue) => {
                      citySelect(newValue);
                    }}
                    value={loc.city_name}
                    sx={{
                      mb: 3,
                      width: "100%",
                      mx: "auto",
                    }}
                    renderInput={(params) => (
                      <CssTextField {...params} label="Select city" />
                    )}
                  />
                </Grid>
                <Grid sm={6} xs={6} item style={{ padding: "0px 20px" }}>
                  <CssTextField
                    label="Postal code"
                    placeholder="Enter Your Postal Code"
                    sx={{ width: "100%" }}
                    value={addressList.postalCode}
                    name="postalCode"
                    onChange={(e) =>
                      handleAddress("postalCode", e.target.value)
                    }
                  />
                </Grid>
                <Grid xs={12} item style={{ padding: "10px 0px" }}>
                  <p
                    style={{
                      fontSize: "21px",
                      borderBottom: "1px solid #707070",
                      padding: "10px 16px",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                    }}
                  >
                    Contact <img onClick={handleAdd} src={Plus} alt="add" />
                  </p>
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{ padding: "0px 10px" }}
                  id="setpublic"
                ></Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  className="update_user_head"
                  id="setpublic"
                >
                  <p>Public</p>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  className="update_user_head"
                  id="setpublic"
                >
                  <p>Status</p>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    padding: "0px 10px",
                  }}
                  id="setpublic"
                ></Grid>
                {contacts.map((contact, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={9} style={{ padding: "0px 10px 0px 20px" }}>
                      <Grid container>
                        <Grid item sm={3} xs={12}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={system}
                            name="system"
                            getOptionLabel={(option) =>
                              option.charAt(0).toUpperCase() +
                              option.substring(1)
                            }
                            disabled={contact.verify ? true : false}
                            onChange={(e, value) =>
                              handleContactChange("system", value, i)
                            }
                            value={contact.system}
                            fullWidth
                            renderInput={(params) => (
                              <CssTextField
                                style={{ marginTop: "16px" }}
                                {...params}
                                label="System"
                                value={contact.system}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={3} xs={12} className="contact_update">
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={use}
                            name="use"
                            getOptionLabel={(option) =>
                              option.charAt(0).toUpperCase() +
                              option.substring(1)
                            }
                            disabled={contact.verify ? true : false}
                            onChange={(e, value) =>
                              handleContactChange("use", value, i)
                            }
                            value={contact.use}
                            fullWidth
                            renderInput={(params) => (
                              <CssTextField
                                style={{ marginTop: "16px" }}
                                {...params}
                                label="Use"
                                value={contact.use}
                                validators={["required"]}
                                errorMessages={["Please Select Purpose"]}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          {contact.system === "phone" ? (
                            <MuiPhoneNumber
                              variant="outlined"
                              defaultCountry="in"
                              id="outlined-number"
                              label="Phone Number"
                              onlyCountries={[
                                "in",
                                "us",
                                "ca",
                                "au",
                                "gb",
                                "ae",
                              ]}
                              disabled={contact.verify ? true : false}
                              className="contact-num"
                              fullWidth
                              name="phone"
                              value={contact.value}
                              onChange={(value) =>
                                handleContactChange("value", value, i)
                              }
                            />
                          ) : (
                            <TextField
                              label="Email"
                              placeholder="john@gmail.com"
                              value={contact.value}
                              name="email"
                              disabled={contact.verify ? true : false}
                              handleChange={(e, value) =>
                                handleContactChange("value", value, i)
                              }
                              validators={["required", "isEmail"]}
                              errorMessages={[
                                "Please enter your email",
                                "Invalid Email",
                              ]}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      className="update_icon d-flex e-align"
                      id="setpublic"
                    >
                      <IconButton
                        className="w-100"
                        aria-label="visible"
                        onClick={() =>
                          handlePublic("public", contact.public, i)
                        }
                      >
                        {contact.public === "HIDE" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="update_icon1 d-flex e-align"
                      id="setpublic"
                    >
                      {contact.verify ? (
                        <p className="w-100">
                          <img src={Status} alt="Public" />
                        </p>
                      ) : (
                        <p
                          className="w-100 cursor"
                          style={{
                            textDecoration: "underline",
                            color: "#0000A2",
                          }}
                          onClick={() =>
                            contact.system === "phone"
                              ? sendOTP(contact.value, i)
                              : sendEmailOTP(contact.value, i)
                          }
                        >
                          Verify
                        </p>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="update_icon2 d-flex e-align"
                      id="setpublic"
                    >
                      {i !== 0 ? (
                        <p className="w-100">
                          <img
                            src={Delete}
                            alt="Public"
                            onClick={() => handleEmailDialog(i)}
                          />
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
              <Grid xs={12} item style={{ padding: "10px 0px" }}>
                <p
                  style={{
                    fontSize: "21px",
                    borderBottom: "1px solid #707070",
                    padding: "10px 16px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  Registration Details{" "}
                  <img
                    onClick={handleAddLicenc}
                    src={Plus}
                    alt="add"
                    className="cursor"
                  />
                </p>
              </Grid>
              <Grid
                container
                spacing={3}
                columnSpacing={3}
                rowSpacing={2}
                className="mb2"
                sx={{ p: 1 }}
              >
                {licence?.map((reg, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={12}>
                      {" "}
                      <p className="w-100 flex__end cursor">
                        <DoDisturbOnIcon
                          sx={{ color: "red" }}
                          onClick={() => deleteLicence(i)}
                        />
                      </p>{" "}
                    </Grid>
                    <FormLayout
                      type="select"
                      options={medicalcounsil}
                      value={reg.council}
                      name="council"
                      handleChange={handleChange(i)}
                      placeholder="Select Council under which Registered"
                      required
                      errorMessages={["Select Council"]}
                    />
                    <FormLayout
                      name="regNo"
                      type="Number"
                      value={reg.regNo}
                      handleChange={handleChange(i)}
                      placeholder="Registration Number"
                      required
                      errorMessages={["Enter registration number"]}
                    />

                    <FormLayout
                      type="dateIn"
                      value={dayjs(reg?.date)}
                      name="date"
                      label="Date of Registration"
                      handleChange={handleChange(i)}
                      required
                      errorMessages={["Select Date of registration"]}
                    />
                    <FormLayout
                      type="dateIn"
                      value={dayjs(reg?.dueDate)}
                      name="dueDate"
                      label="Due Date for Renewal"
                      handleChange={handleChange(i)}
                      required
                      errorMessages={["Select due date"]}
                    />

                    <FormDivider />
                  </React.Fragment>
                ))}
              </Grid>

              <Grid xs={12} item style={{ padding: "10px 0px" }}>
                <p
                  style={{
                    fontSize: "21px",
                    borderBottom: "1px solid #707070",
                    padding: "10px 16px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  Education Details
                  <img
                    onClick={handleAddEducation}
                    src={Plus}
                    alt="add"
                    className="cursor"
                  />
                </p>
              </Grid>
              <Grid container spacing={3} sx={{ p: 1 }}>
                {edu?.map((data, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={12}>
                      {" "}
                      <p className="w-100 flex__end cursor">
                        <DoDisturbOnIcon
                          sx={{ color: "red" }}
                          onClick={() => deleteEducation(i)}
                        />
                      </p>{" "}
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <label>University (from which degree is completed)</label>
                      <CustomAutocomplete
                        placeholder="Search University Name"
                        value={data.issuer}
                        name="issuer"
                        handleOrg={handleQua(i)}
                        required
                        errorMessages={["Please enter university name"]}
                      />
                    </Grid>

                    <FormLayout
                      value={data.identifier}
                      handleChange={handleQua(i)}
                      name="identifier"
                      label="Identity Number"
                      type="Number"
                      placeholder="Certification No./Registration No."
                      required
                      errorMessages={["Enter Certification/Registration No."]}
                    />

                    <FormLayout
                      type="dateIn"
                      views={["year", "month"]}
                      value={dayjs(data.dateIss)}
                      name="dateIss"
                      label="Date of Issue"
                      handleChange={handleQua(i)}
                      required
                      errorMessages={["Select Date of registration"]}
                    />
                    <Grid
                      item
                      container
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ boxShadow: "2px 3px 10px white" }}
                    >
                      <Grid item xs={5} sm={5} md={5}>
                        <FormLayout
                          input="select"
                          name="degree"
                          id="select-option"
                          handleChange={handleQua(i)}
                          onInputChange={(e) =>
                            "filterBodySiteFn(e?.target?.value)"
                          }
                          fullWidth
                          label="System of Medicine"
                          value={data?.degree}
                          placeholder="Select"
                          options={systemsData}
                          getOptionLabel={(option) => option.display || ""}
                          size={12}
                          required
                          sx={{
                            borderRadius: "0px",
                            ".MuiInputBase-root": {
                              borderRadius: "10px 0px 0px 10px",
                              borderRight: "1px solid black",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={7} sm={7} md={7}>
                        <FormLayout
                          sx={{
                            borderRadius: "0px",
                            ".MuiInputBase-root": {
                              borderRadius: "0px 10px 10px 0px",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          input="select"
                          name="course"
                          label="Select Type of course"
                          id="select-option"
                          handleChange={handleQua(i)}
                          fullWidth
                          value={data.course}
                          placeholder="Select"
                          options={degreeType?.[0]?.concept || []}
                          getOptionLabel={(option) => option.display}
                          size={12}
                        />
                      </Grid>
                    </Grid>

                    <FormDivider />
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <div style={{ textAlign: "right", margin: "0px 15px 0px 0px" }}>
              {data.birthDate === null ? (
                ""
              ) : (
                <Button
                  type="button"
                  onClick={handleDialog}
                  className="close-article"
                >
                  Cancel
                </Button>
              )}

              <Button
                disabled={UserService.getPractitionerFhirId() ? false : true}
                type="submit"
                className="save-btn btn__primary"
                onClick={() => {
                  if (
                    form.birthDate !== "Invalid Date" &&
                    form.birthDate !== null &&
                    UserService.getPractitionerFhirId()
                  ) {
                    console.log(licence, "show", edu, form);
                    //  ident= hprInfo
                    let hprIdDetails = hprInfo;
                    // console.log(hprIdDetails, "1401", UsersDatas);
                    handleSubmit(
                      form,
                      contacts,
                      addressList,
                      edu,
                      licence,
                      UsersDatas,
                      hprIdDetails
                    );
                    setOpened(true);
                  }
                }}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </ValidatorForm>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={20000}
        opened={opened}
        onClose={handleClose}
        message="Uploaded..."
      />
      <>
        <Dialog
          open={opendeleteEmailAlert}
          close={handleCloseEmailDialog}
          maxWidth={"sm"}
          fullWidth
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-between",
              }}
            >
              <p sx={{ fontSize: "16px" }}>Do you want Delete Email</p>
              <p sx={{ display: "flex", alignItem: "center" }}>
                {" "}
                <CloseIcon
                  onClick={handleCloseEmailDialog}
                  className="cursPoint"
                />
              </p>
            </div>
          </DialogTitle>
          <FormDivider />
          <DialogContent>
            <div
              style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
            >
              <Button
                variant="outlined"
                sx={{ padding: "4px 20px !important" }}
                onClick={handleCloseEmailDialog}
              >
                {" "}
                close
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: "20px", padding: "4px 20px !important" }}
                onClick={() => {
                  handleDelete(particularIdx);
                  handleCloseEmailDialog();
                }}
              >
                {" "}
                Done
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {/* <DialogEmail open={opendeleteEmailAlert} close={handleCloseEmailDialog} heading="Do you Really want Delete Email" children={<Actions close={handleCloseEmailDialog} name="Done" />} /> */}
      </>
    </>
  );
}
