import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimelineIcon from '@mui/icons-material/Timeline';
import scroll from "../../../Atom/svg/scroll.svg";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button'
import DialogBox from '../../../DialogBox/dialog'
import Layout from './layout'
import moment from 'moment'
// import View from './expview.js'
import Add from './expcrud'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, getMetaData } from '../../../../redux/Actions/actions';
import URL from '../../../apiurl'
import Tooltip from '@mui/material/Tooltip';
import { Avatar, Card, Dialog } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import '../myprofile.css';
import Stethscope from '../../../../icons/stethscope.svg';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import '../updateprofile.css';
import OrgLogo from '../../../../images/orglogo.svg'
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ExpView = (props) => {
    // console.log("hello")
    // console.log(props)

    const [modal, setModal] = useState({ open: false, id: null })
    return (
        <div className="">
            <div className="sidebar__stat  bg__blue sidebar__padding">
                <DialogBox open={modal.open} width="xs" handleDialog={() => setModal({ open: false, id: null })} Content={<p>Do you want to delete ?</p>}
                    Actions={<div><Button variant="contained" tabIndex={1} sx={{ mr: 2, borderRadius: 5, textTransform: "capitalize", height: "30px" }} onClick={() => { props.handleDelete(modal.id); setModal({ open: false, id: null }) }}>Yes</Button>
                        <Button variant="contained" tabIndex={2} sx={{ borderRadius: 5, textTransform: "capitalize", height: "30px" }} onClick={() => { setModal({ open: false, id: null }) }}>No</Button></div>}
                />
                <div className="sidebar__substat ta__center mt-10">
                    <img src={Stethscope} style={{ fontSize: "30px", color: '#1C4E9D' }} />
                    <div className="font-caption fw__semibold sidebar__lh  sidebar__ml">Experience</div>
                </div>
            </div>
            {/* org */}
            {/* {(console.log("hello"))} */}
            {
                props.exp.length > 0 ? props.exp.map(e => (
                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" key={e.id}>
                        <div className="sidebar__substat ta__center">

                            {/* <img src={medxpert} alt="medxpert" /> */}
                            <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                {/* <DomainIcon style={{fontSize: '30px'}}/> */}
                                {/* <img src={Organisation} alt="organisation" width='25px' heigth="20px" /> */}
                                {e.logo ?
                                    <Avatar src={e.logo} style={{ width: '100%', height: "100%", borderRadius: '7px' }} />
                                    :
                                    // <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                    <div style={{ backgroundColor: '#A8E8FE', width: '100%', height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                                    </div>
                                }
                            </div>
                            {/* <WorkIcon sx={{px:1.5}}/> */}
                            {/* <div className="sidebar__title sidebar__ml " style={{textTransform: 'capitalize'}}>{e.name} <br /><span className="fontItalic">{e.designation}<br /></span>{format(e.startDate,'MMM yyyy')} - {e.endDate ? format(e.endDate,'MMM yyyy') : 'present' }</div> */}
                            <div className="sidebar__ml " style={{ fontSize: '13px', textTransform: 'capitalize' }}>
                                <p style={{ paddingBottom: '3px' }}>{e.designation}</p>
                                <p style={{ fontWeight: 'bold', paddingBottom: '3px' }}>{`${(e.unofficialOrganisationName) ? e.unofficialOrganisationName : e.organisationName}`}</p>
                                <p>{moment(e.fromDate).format('MMM yyyy')} - {e.toDate ? moment(e.toDate).format('MMM yyyy') : 'present'}</p></div>
                        </div>
                        <div className="sidebar__statNumber">
                            <Tooltip title='Edit Experience'>
                                <IconButton onClick={() => props.handleEdit(e)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete Experience'>
                                <IconButton onClick={() => {
                                    setModal({ open: true, id: e.id })
                                }}>
                                    <DeleteIcon sx={{ color: "red" }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )) :

                    <div className="sidebar__stat sidebar__padding bg__white e-align icon-show">
                        <p>No Experience</p>
                    </div>

            }

        </div >
    )
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default function Experience() {

    const [exp, setExp] = useState([])
    const [open, setOpen] = useState(false)
    const [check, setCheck] = useState(true)
    const [edit, setEdit] = useState(false)
    const [id, setId] = useState(parseInt(0))
    const [view, setView] = useState(false)
    const experience = useSelector(state => state.userProfile.exp)
    const dispatch = useDispatch()
    const [count, setCount] = useState(3)

    const [state, setState] = useState(
        { id: null, organisation: null, designation: '', city: '', fromDate: null, toDate: null, status: '' })

    const history = useHistory();

    console.log("unofficialOrganisation111")
    useEffect(() => {
        // getData()
        setExp(experience)
    }, [experience])



    const getData = () => {
        dispatch(fetchProfile(sessionStorage.getItem("userId")))
    }

    const handleVisibility = async (exp) => {
        let data = {
            "designation": exp.designation,
            "fromDate": moment(exp.fromDate).format('YYYY-MM-DD'),
            "index": exp.id,
            "logo": "",
            "metadata": await getMetaData(exp.metadata),
            "id": exp.id,
            "organisation": exp.organisation,
            "city": exp.city,
            "organisationName": exp.organisationName,
            "status": exp.status === "SHOW" ? "HIDE" : "SHOW",
            "toDate": exp.toDate ? moment(exp.toDate).format('YYYY-MM-DD') : '',
            "userId": sessionStorage.getItem("userId")
        };
        update(data, exp.id);
    }

    const handlecity = (event, value) => {
        // console.log(value, "fghjkl", event)
        setState({ ...state, city: value })
    }

    const handleChange = (name, value) => {
        // console.log(name, value)
        if (name === 'organisation') {
            setState({ ...state, [name]: value })
        }
        else {
            setState({ ...state, [name]: value })
        }
    }

    const handleOrg = (name, value, newOrg, logo) => {
        console.log(logo, name, "unofficialOrganisation", value, newOrg)
        if (newOrg) {
            setState({ ...state, organisationName: "", organisation: 0, unofficialOrganisation: value, unofficialOrganisationName: name })
        } else {
            setState({ ...state, organisationName: name, organisation: value, unofficialOrganisation: 0, unofficialOrganisationName: "", logo: logo })
        }
    }
    console.log(state, "statestate")
    const handleAdd = async (requestFrom) => {
        console.log(state, "uuu", requestFrom)
        let data = {
            "designation": state.designation,
            "fromDate": moment(state.fromDate).format('YYYY-MM-DD'),
            "metadata": await getMetaData(),
            "organisation": state?.organisationName?.identifier?.[1]?.value,
            "city": state.city,
            "status": "SHOW",
            "toDate": check ? '' : moment(state.toDate).format('YYYY-MM-DD'),
            "userId": sessionStorage.getItem("userId"),
            "unofficialOrganisation": state.unofficialOrganisation,
            "unofficialOrganisationName": state.unofficialOrganisationName
        }
        // console.log(data)
        axios.post(`${URL.addExperience}`, data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`,
            }
        })
            .then(res => res.data).then(resdata => {
                // console.log(resdata)
                getData();
            }
            ).catch(err => {
                if (err.response.status == 422 && !requestFrom === "RoleRequest") {
                    alert("You have already added experience request on this organization. You can't add another experience request on this organization")
                } else {
                    // alert('Couldn\'t send request.Try again later')
                }
            })
        setCheck(true)
        setOpen(false)
    }

    const handleCancel = () => {
        // setCheck(false)
        setOpen(false)
        setEdit(false)
    }

    const handleEdit = (data) => {
        data.toDate !== null ? setCheck(false) : setCheck(true)
        setEdit(true)
        setOpen(true)
        setState(data)
        setId(data.id)
    }
    const handleUpdate = async () => {
        let id = state?.id;
        setOpen(false)
        setEdit(false)
        console.log(id, "ddddddiii")
        let data = {
            "designation": state.designation,
            "fromDate": moment(state.fromDate).format('YYYY-MM-DD'),
            "index": id,
            "city": state.city,
            "logo": state.logo,
            "metadata": await getMetaData(state.metadata),
            "id": id,
            "organisation": state.organisation,
            "organisationName": state.organisationName,
            "unofficialOrganisation": state.unofficialOrganisation,
            "unofficialOrganisationName": state.unofficialOrganisationName,
            "status": state.status,
            "toDate": check ? '' : moment(state.toDate).format('YYYY-MM-DD'),
            "userId": sessionStorage.getItem("userId")
        };
        update(data, id);

    }

    const update = (data, id) => {
        console.log(id, "experienceid")

        axios.put(`${URL.updateExperience}/${id}`, data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        })
            .then(res => res.data).then(resData => { getData(); }).catch(error => { })

    }
    const handleDelete = (id) => {
        console.log(id, "id")
        axios.delete(`${URL.deleteExperience}/${id}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        })
            .then(response => {

                getData();
            }).catch(error => { })

    }

    // const handleDeleteExperi = () => {

    // }


    const addFun = () => {
        setOpen(true)
        setEdit(false);
        setCheck(false)
        setState({ name: '', designation: '', toDate: null, fromDate: null })
    }

    const onDragEnd = result => {
        if (!result.destination) return;
        const items = reorder(
            exp,
            result.source.index,
            result.destination.index
        );
        setExp(items);
        let id = (items.map(i => {
            return i.id
        }))
        let uid = parseInt(sessionStorage.getItem("userId"))
        let data = {
            "userId": uid,
            "orderList": id,
            "recordsType": "EXPERIENCE"
        }
        axios.put(`${URL.dragDrop}/1`, data, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
            }
        }).then(res => {
            // console.log(res)
            // getData()
        }).catch(err => { })

    };

    return (
        <div className="sidebar__mt border_img" style={{ borderRadius: "10px" }}>

            {/* CRUD */}
            <DialogBox open={open} handleDialog={() => setOpen(false)}
                Title={`${edit ? 'Edit' : 'Add'} Experience`} onClose={() => setOpen(false)}
                Content={<Add state={state} handleChange={handleChange} handleOrg={handleOrg} cancel={() => handleCancel()} handleSubmit={edit ? handleUpdate : handleAdd} submit={edit ? "Update" : "Add"} check={check} handleCheck={(e) => setCheck(e.target.checked)} handlecity={handlecity}
                />
                }

            // Actions = { <> {edit ? <Button onClick={(e)=>handleUpdate()}>Update</Button> : <Button onClick={(e)=>handleAdd()}>Add</Button>}
            // <Button onClick={()=>handleCancel()}>Cancel</Button></> }

            />
            {/* View */}
            <DialogBox open={view} handleDialog={() => setView(false)}
                Content={<ExpView exp={exp} handleEdit={handleEdit} handleDelete={handleDelete} />}
                Actions={<Button onClick={() => setView(false)}>Close</Button>}
                style={{ padding: '0px' }}
            />

            <Layout HeadIcon={<img src={Stethscope} style={{ fontSize: "30px" }} />}
                Title="Experience" handleAdd={() => addFun()} handleView={() => setView(true)}
            />

            {exp.length > 0 ?

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                className="list"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ width: "100%" }}
                            >

                                {exp.slice(0, count).map((e, index) => (
                                    <Draggable
                                        key={e.id}
                                        draggableId={e.id.toString()}
                                        index={index}
                                        className="lol"
                                    >
                                        {(provided, snapshot) => (
                                            <div className="sidebar__stat just_space_btwn sidebar__padding bg__white e-align icon-show" key={e.id}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div className="sidebar__substat ta__center" style={{ width: "100%", justifyContent: "space-between", }}>
                                                    <div className="sidebar__substat ta__center" style={{ width: "85%", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="my-img domain-icon margin_exp" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                                                {/* <DomainIcon style={{fontSize: '30px'}}/> */}
                                                                {e.logo ?
                                                                    <Avatar src={e.logo} style={{ width: '100%', height: "100%", borderRadius: '7px' }} />
                                                                    :
                                                                    <div style={{ backgroundColor: '#A8E8FE', width: '100%', height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                                                                    </div>
                                                                    // <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                                                                }
                                                            </div>
                                                            <div>
                                                                <div className="sidebar__ml capitalize cursor" style={{ fontSize: '13px', marginLeft: '20px' }} onClick={e.organisation !== 0 ? () => history.push(`/admin/organization/${e.organisation}`) : () => { }}>
                                                                    <p className='des_text' style={{ paddingBottom: '0px', }}>{e.designation}</p>
                                                                    <p className='unofficial_text' style={{ paddingBottom: '0px' }}>{`${(e.unofficialOrganisationName) ? e.unofficialOrganisationName : e.organisationName}`} </p>
                                                                    <p className='city_text' style={{ paddingBottom: '0px' }}>{(e.city) ? e.city : ""}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* {console.log(e)} */}
                                                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                            <div>
                                                                <p className='data_exp_text'>{" "}{moment(e.fromDate).format('MMM yyyy')}{" "}{e.toDate ? moment(e.toDate).format('- MMM yyyy') : '(Till Date)'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sidebar__statNumber icon-none" style={{ width: "15%" }}>
                                                        <div style={{ display: 'inline-flex', padding: '8px' }}>
                                                            <img src={scroll} alt="scroll" />
                                                        </div>
                                                        <IconButton aria-label="visible" onClick={() => handleVisibility(e)}>
                                                            {e.status === "SHOW" ? <VisibilityIcon style={{ color: 'black' }} /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>)}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                :

                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show">
                    <div className="sidebar__substat ta__center">
                        <div>
                            {/* <p onClick={() => addFun()}
                                className="cursor d-flex e-align follow-link"
                                style={{ fontSize: "15px", marginTop: '10px' }}>
                                <AddCircleOutlineIcon sx={{ fontSize: '20px' }} />&nbsp;Add Experience
                            </p> */}
                            <p style={{ fontSize: '14px', marginTop: '6px', color: '#635660' }}>Your work experience comes up here ! </p>
                        </div>
                    </div>
                </div>
            }
            {/* test */}


            {
                (exp.length > 3) &&

                <Card className='p1' style={{ borderTop: "1px solid lightgrey", borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>
                    {


                        exp.length > 3 && count === 3 ?
                            <div className='flx-center cursor' style={{ justifyContent: "center" }} onClick={() => setCount(exp.length)}  ><span className="fntSm14 fntSemiBold">Show More</span> <span style={{ height: "15px", }}><KeyboardArrowDownIcon fontSize='' /></span></div>
                            :

                            <div className='flx-center cursor' style={{ justifyContent: "center" }} onClick={() => setCount(3)}><span className="fntSm14 fntSemiBold">Show Less</span> <span style={{ height: "15px", }}><KeyboardArrowUpIcon fontSize='' /></span></div>

                    }
                </Card >
            }
            {/* 
            <div className="work__space sidebar__padding bg__white e-align icon-show">
                <div className="sidebar__substat ta__center">
                    {exp.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(exp.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{exp.length - 3} More Experience{(exp.length - 3) > 1 ? 's' : ''}&nbsp;
                        <MdOutlineKeyboardArrowDown />
                    </p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}

                </div>
            </div> */}

        </div >
    );
}