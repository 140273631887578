import React, { useEffect, useState } from "react";
import { Card, Grid, Button, Box, Typography } from "@mui/material";
import DefaultDoctorImg from "../../../icons/Userprofilelogo.svg";
import EditImg from "../../../../src/icons/edit_icon.svg"
import MessageIcon from "../../../icons/message1.svg";
import LanguageIcon from "../../../icons/language.svg";
import Stethoscope from "../../../icons/stethoscope2.svg";
import { useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";
import { DriveFileRenameOutlineOutlined, Edit, EventAvailable, MarginRounded, Message } from "@mui/icons-material";
import moment from "moment";
const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };


const DoctorsInfoCard = ({ practitioner, practitionerRole, type, opnEdit, AllowEdit }) => {
    const history = useHistory();
    const [WorkingDays, setWorkingDays] = useState([]);
    const [timings, setTimings] = useState({});
    const [activeDay, setActiveDay] = useState(null);

    console.log(practitioner, "practitioner");
    console.log(practitionerRole, "practitionerRole")

    const timingInfo = practitionerRole?.resource?.availableTime
    useEffect(() => {
        if (timingInfo) {
            let data = [];
            const date = new Date();
            const today = days[date.getDay() - 1];
            console.log(timingInfo, "900")
            {
                timingInfo?.map((item, i) => {
                    let openingTime = item.availableStartTime;
                    let closingTime = item.availableEndTime;
                    if (item.allDay == true) {
                        item?.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else if (item.allDay == false) {
                        item.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else {
                        item.daysOfWeek.map((child) => {
                            data.push({
                                day: child,
                                workingHours: [
                                    { openingTime: openingTime, closingTime: closingTime },
                                ],
                                today: child == today ? true : false,
                            });
                        });
                    }
                });
            }


            const mergedSchedule = [];

            for (let i = 0; i < data.length; i++) {
                let day = data[i].day;
                let hours = data[i].workingHours;

                let existingDay = mergedSchedule.find((item) => item.day === day);
                if (existingDay) {
                    existingDay.workingHours = existingDay.workingHours.concat(hours);
                } else {
                    mergedSchedule.push(data[i]);
                }
            }

            const sorted = mergedSchedule.sort((a, b) => {
                return daysCompare[a.day] - daysCompare[b.day];
            });
            setWorkingDays(sorted);
        }
    }, [timingInfo])
    useEffect(() => {
        const date = new Date();
        const today = days[date.getDay() - 1];
        WorkingDays?.find((item) => {
            if (item.day == today) {
                // console.log(item)
                setTimings(item);
            }
        });
    }, [WorkingDays]);

    const customStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };


    return (

        <Card sx={{
            // padding: "10px 10px 0px",
            minwidth: "300px",
            height: "285px",
            position: "relative",
            boxShadow: "0px 0px 1.6px #888",
            backgroundColor: "white",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }} className="mv1 cursor">

            <Box sx={{ width: "100%", padding: "10px 10px 0px", }}>
                <div style={{ width: "100%", display: "flex", alignItems: "", gap: "10px", border: "" }}>
                    <div style={{
                        width: '75px', height: '70px', border: "3px solid white", borderRadius: "8px", boxShadow:
                            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                        backgroundColor: "white"
                    }}>
                        {
                            (practitioner?.resource?.photo?.[0]?.url &&
                                practitioner?.resource?.photo?.[0]?.url !== "undefined" &&
                                practitioner?.resource?.photo?.[0]?.url !== "null" &&
                                practitioner?.resource?.photo?.[0]?.url !== "") ?
                                <img
                                    src={practitioner?.resource?.photo?.[0]?.url}
                                    width="100%"
                                    height="100%"
                                    style={{ borderRadius: "5px", objectFit: "cover", objectPosition: "center" }}

                                />
                                :
                                (
                                    <img src={DefaultDoctorImg} width="100%"
                                        height="100%"
                                        style={{ borderRadius: "0px", objectFit: "cover", objectPosition: "center" }} />
                                )

                        }
                        {/*                     
                    <img
                        src={
                 
                                ? practitioner.resource.photo[0].url
                                : <DefaultDoctorImg />
                        }
                        alt="Practitioner"
                        style={{ width: "75px", height: "70px", objectFit: "cover", borderRadius: "5px" }}
                    /> */}
                    </div>
                    <div style={{
                        width: 'calc(100% - 80px)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        <div>
                            <div
                                className="fntLg fntSemiBold capitalize"
                                // onClick={() => history.push(`/profile/${practitioner?.resource?.name?.[0]?.text?.replace(/ /g, '').toLowerCase()}-${practitioner?.resource?.identifier?.[0]?.value}`)}
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                {practitioner?.resource?.name?.[0]?.text}
                            </div>
                            <div className="font-sm capitalize" style={{
                                color: "#707070",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"


                            }}>
                                {practitioner?.resource?.name?.[0]?.suffix?.length > 0 &&
                                    practitioner?.resource?.name?.[0]?.suffix?.join(" , ")
                                }
                            </div>
                            {practitionerRole?.resource?.identifier?.[0]?.value && (
                                <div className="fntMd fntSemiBold capitalize"
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}


                                >
                                    {practitionerRole?.resource?.identifier?.[0]?.value}
                                </div>
                            )}

                            {practitionerRole?.resource?.specialty?.length > 0 && (
                                <div className="flx-center" >
                                    <div>
                                        <img src={Stethoscope} width={15} height={15} alt="Stethoscope" />
                                    </div>
                                    <div className="fntSm14 capitalize"

                                        style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}


                                    >
                                        {practitionerRole?.resource?.specialty?.map(e => e?.coding?.[0]?.display + " ")}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* <div style={{ width: "100%", display: "flex", alignItems: "", gap: 10, border: "1px solid red" }}>
                <div style={{ width: '80px' }}>
                    <img src={(practitioner?.resource?.
                        photo && practitioner?.resource?.
                            photo?.[0]?.url != "undefined" && practitioner?.resource?.
                                photo?.[0]?.url != "null" && practitioner?.resource?.
                                    photo?.[0]?.url != "") ? practitioner?.resource?.
                                        photo?.[0]?.url : DefaultDoctorImg} style={{ width: "80px", height: "80px", borderRadius: "10px", border: "", }} />
                </div>
                <div style={{
                    width: 'calc(100% - 80px)',
                    border: "1px solid green",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }} >
                    <div >
                        <div style={{ border: "1px solid yellow" }} className="fntLg  fntSemiBold capitalize" onClick={() => history.push(`/profile/${practitioner?.resource?.name?.[0]?.text?.replace(/ /g, '').toLowerCase()}-${practitioner?.resource?.identifier?.[0]?.value}`)}>{practitioner?.resource?.name?.[0]?.text} </div>

                        <div className="font-sm capitalize" style={{ color: "#707070", }}>{practitioner?.resource?.name?.[0]?.suffix?.length > 0 &&

                            <>
                                {practitioner?.resource?.name?.[0]?.suffix?.join(" , ")}
                            </>

                        }</div>
                        {
                            practitionerRole?.resource?.identifier?.[0]?.value && <div className="fntMd fntSemiBold  capitalize">{practitionerRole?.resource?.identifier?.[0]?.value}</div>
                        }

                        {
                            practitionerRole?.resource?.specialty?.length > 0 &&
                            <div className="flx-center">
                                <div>
                                    <img src={Stethoscope} width={15} height={15} />
                                </div>
                                <div className="fntSm14 capitalize">{practitionerRole?.resource?.specialty?.map((e) => e?.coding?.[0]?.display + " ")}</div>
                            </div>
                        }
                    </div>
                </div>
            </div> */}

                {/* <Grid container spacing={""} gap={2} className="" >
                <Grid item>
                    <img src={(practitioner?.resource?.
                        photo && practitioner?.resource?.
                            photo?.[0]?.url != "undefined" && practitioner?.resource?.
                                photo?.[0]?.url != "null" && practitioner?.resource?.
                                    photo?.[0]?.url != "") ? practitioner?.resource?.
                                        photo?.[0]?.url : DefaultDoctorImg} style={{ width: "80px", height: "80px", borderRadius: "10px", border: "", }} />
                </Grid>
                <Grid item >
                    <div style={{ width: 'calc(100% - 80px)', border: "1px solid green" }}>
                        <div className="fntLg  fntSemiBold capitalize" onClick={() => history.push(`/profile/${practitioner?.resource?.name?.[0]?.text?.replace(/ /g, '').toLowerCase()}-${practitioner?.resource?.identifier?.[0]?.value}`)}>{practitioner?.resource?.name?.[0]?.text} </div>

                        <div className="font-sm capitalize" style={{ color: "#707070", }}>{practitioner?.resource?.name?.[0]?.suffix?.length > 0 &&

                            <>
                                {practitioner?.resource?.name?.[0]?.suffix?.join(" , ")}
                            </>

                        }</div>
                        {
                            practitionerRole?.resource?.identifier?.[0]?.value && <div className="fntMd fntSemiBold  capitalize">{practitionerRole?.resource?.identifier?.[0]?.value}</div>
                        }
                        {
                            // practitionerRole?.resource?.code?.length > 0 && <div className="fntMd fntSemiBold">{practitionerRole?.resource?.code?.map((e) => e?.coding?.[0]?.display + " ")}</div>
                        }
                        {
                            practitionerRole?.resource?.specialty?.length > 0 &&
                            <div className="flx-center">
                                <div>
                                    <img src={Stethoscope} width={15} height={15} />
                                </div>
                                <div className="fntSm14 capitalize">{practitionerRole?.resource?.specialty?.map((e) => e?.coding?.[0]?.display + " ")}</div>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid> */}

                {
                    practitioner?.resource?.communication?.length > 0 &&
                    <div className="flx-center mv0-5 pv0-5">

                        <div><img src={LanguageIcon} width={15} height={15} alt="langIcon" /></div>
                        <div className="fntSm"
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}

                        >&nbsp;&nbsp;
                            <span className="fntSm fntSemiBold capitalize">Communicate in</span><span style={{ color: "#5465AA" }}> {practitioner?.resource?.communication?.map((e) => e?.coding?.[0]?.display + " ")}</span>
                        </div>
                    </div>
                }
                {/* {
                    practitioner?.resource?.communication?.length > 0 &&
                    <div className="flx-center mv0-5 pv0-5">

                        <div><img src={LanguageIcon} width={15} height={15} alt="langIcon" /></div>
                        <div className="fntSm"
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}

                        >&nbsp;&nbsp;
                            <span className="fntSm fntSemiBold capitalize">Communicate in</span><span style={{ color: "#5465AA" }}> {practitioner?.resource?.communication?.map((e) => e?.coding?.[0]?.display + " ")}</span>
                        </div>
                    </div>
                } */}
                {console.log(practitioner, practitionerRole, "man9001")}
                {practitionerRole?.resource?.availableTime ?
                    <Box
                        sx={{
                            width: "100%",
                            color: "#000000",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: { xs: '12px' },
                            display: 'flex',
                            flexDirection: "row",
                            gap: '15px',
                            marginBottom: "5px"
                        }}
                    >
                        <div className='flex flex-row pt-1'>
                            <EventAvailable
                                sx={{ fontSize: "18px", color: '#E54290', mt: '3px' }}
                            />
                        </div>
                        <div className='text-[16px]'>Available</div>
                        {/* <div style={{ display: "flex", gap: "5px", flexWrap: 'wrap' }}>
                        {console.log(WorkingDays, "WorkingDays")}
                        {WorkingDays?.map((item, i) => {
                            return (
                                <Button
                                    key={i}
                                    variant="outlined"
                                    onClick={() => {
                                        setTimings(item);
                                        setActiveDay(i);
                                    }}
                                    sx={{
                                        minWidth: "35px",
                                        height: '26px',
                                        border: '1px solid #E54290',
                                        background:
                                            activeDay === i && activeDay !== null
                                                ? "#480223"
                                                : item.today && activeDay === null
                                                    ? "#480223"
                                                    : !item.allDay && !item.hasOwnProperty("workingHours")
                                                        ? "#fff"
                                                        : "#fff",
                                        padding: "0px 7px",
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: activeDay === i && activeDay !== null
                                                ? "#fff"
                                                : item.today && activeDay === null
                                                    ? "#fff"
                                                    : !item.allDay && !item.hasOwnProperty("workingHours")
                                                        ? "#E54290"
                                                        : "#E54290",
                                            textTransform: "capitalize",
                                        }}
                                        style={customStyles}
                                    >
                                        {item.day}
                                    </Typography>
                                </Button>
                            );
                        })}
                    </div> */}
                        <div sx={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", gap: "5px", margin: "4px 0px 0px", flexWrap: 'wrap' }}>
                                {WorkingDays?.map((item, i) => {
                                    return (
                                        <Button
                                            key={i}
                                            variant="outlined"
                                            onClick={() => {
                                                setTimings(item);
                                                setActiveDay(i);
                                            }}
                                            sx={{
                                                minWidth: "35px",
                                                height: '25px',
                                                border: '1px solid #E54290',
                                                background:
                                                    activeDay === i && activeDay !== null
                                                        ? "#480223"
                                                        : item.today && activeDay === null
                                                            ? "#480223"
                                                            : !item.allDay && !item.hasOwnProperty("workingHours")
                                                                ? "#fff"
                                                                : "#fff",
                                                padding: "0px 7px",
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: activeDay === i && activeDay !== null
                                                        ? "#fff"
                                                        : item.today && activeDay === null
                                                            ? "#fff"
                                                            : !item.allDay && !item.hasOwnProperty("workingHours")
                                                                ? "#E54290"
                                                                : "#E54290",
                                                    textTransform: "capitalize",
                                                }}
                                                style={customStyles}
                                            >
                                                {item.day}
                                            </Typography>
                                        </Button>
                                    );
                                })}
                            </div>
                            {practitionerRole?.resource?.availableTime ?
                                <Box
                                    sx={{
                                        color: "#000000",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        mt: { xs: '10px', md: '-5px' },
                                        display: 'flex',
                                        gap: '15px',
                                        flexDirection: { xs: 'column', sm: 'row' }
                                    }}
                                >
                                    {/* <div className='flex pt-3 gap-1'>
                                <EventAvailable
                                    sx={{ fontSize: "18px", color: '#E54290', mr: "5px", mt: '3px' }}
                                />
                                <div className='text-[16px]'>Available</div>
                            </div> */}
                                    <div className='pt-2'>
                                        {timings && timings.allDay ? (
                                            <Typography variant="body2" className="timing" style={customStyles}>
                                                All Day
                                            </Typography>
                                        ) : timings.allDay === false ? (
                                            <Typography variant="body2" className="timing" style={customStyles}>
                                                No Slot available
                                            </Typography>
                                        ) :
                                            (
                                                timings?.workingHours ?
                                                    timings?.workingHours?.map((item, ind) => {
                                                        return (
                                                            <Typography
                                                                key={ind}
                                                                variant="body2"
                                                                className="timing"
                                                                sx={{ whiteSpace: { xs: 'normal', sm: 'nowrap' } }}
                                                                style={customStyles}
                                                            >
                                                                {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                                                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                                                {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                                                            </Typography>
                                                        );
                                                    }) : <Typography variant="body2" className="timing" sx={{ whiteSpace: { xs: 'normal', sm: 'nowrap' } }} style={customStyles}>
                                                        Today No Slots available
                                                    </Typography>
                                            )
                                        }
                                    </div>
                                </Box> :
                                <Typography
                                    sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        mt: { xs: '15px', md: '10px' },
                                        display: 'flex',
                                        gap: '10px',
                                        flexDirection: { xs: 'column', sm: 'row' }
                                    }}
                                >
                                    <EventAvailable sx={{ fontSize: "18px", color: '#E54290', mt: '2px' }} />
                                    <div className='text-[16px]'>Available</div>
                                    <Typography sx={{ fontSize: "16px", color: "#0F4BBE" }}>No Schedule Slots Available</Typography>
                                </Typography>
                            }
                        </div>
                    </Box>
                    :
                    ""
                }
            </Box>
            <Box sx={{ width: "100%" }}>
                {console.log(type, "type100", AllowEdit)}
                {
                    type == "people" &&
                    <div style={{ width: "99.7%", }} >
                        <div style={{
                            width: "98%", height: "43px", display: "flex", justifyContent: "space-evenly", alignItems: "center",
                            padding: "0", borderTop: "1px solid #e0e0e0", marginLeft: "5.5px"
                        }}>
                            {
                                // AllowEdit === "PERMIT" &&
                                <>
                                    <Box
                                        variant="outlined"
                                        // startIcon={<img src={EditImg} width={14} height={14} alt="edit" />}
                                        sx={{
                                            width: "50%",
                                            height: "100%",
                                            fontSize: { xs: "10px", sm: "14px", md: "14px", lg: "14px" },
                                            textTransform: "capitalize",
                                            // borderColor: '#205072',
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: '#205072',
                                            "&:hover": { borderColor: "#205072" }
                                        }}
                                        onClick={() => opnEdit(practitioner, practitionerRole)}
                                    >
                                        {/* <img src={EditImg} width={14} height={14} alt="edit" /> */}
                                        {/* <DriveFileRenameOutlineOutlined sx={{ fontSize: '14px' }} /> */}
                                        <Typography variant="p" sx={{ marginLeft: "6px", marginBottom: "2px" }}>Edit</Typography>
                                    </Box>
                                    {/* #c8c8c8 #0000004d */}
                                    <Box sx={{ height: "100%", borderLeft: "1px solid #b6b3b3" }}></Box>
                                </>
                            }
                            <Box
                                variant="outlined"
                                // startIcon={ }
                                sx={{
                                    width: "50%",
                                    height: "100%",
                                    color: '#205072', textTransform: "capitalize",
                                    fontSize: { xs: "10px", sm: "14px", md: "14px", lg: "14px" },
                                    display: 'flex', justifyContent: "center", alignItems: "center"
                                }}
                            >
                                {/* <img src={MessageIcon} width={14} height={14} alt="message" /> */}
                                {/* <Message sx={{ fontSize: '14px' }} /> */}
                                <Typography variant="p" sx={{ marginLeft: "5px", marginBottom: "2px" }}>Message</Typography>
                            </Box>
                        </div>
                    </div>
                }
                {
                    type == "non-people" &&
                    <div style={{ position: "absolute", bottom: "10px", width: "100%", display: "flex", justifyContent: "center" }}>
                        <div style={{}}>
                            <Button
                                variant="outlined"
                                startIcon={<img src={MessageIcon} width={14} height={14} alt="message" />}
                                style={{ backgroundColor: "#205072", color: "#FFFFFF", textTransform: "capitalize", borderRadius: "10px" }}
                                sx={{
                                    width: { xs: "150px", sm: "150px", md: "150px", lg: "150px" },
                                    height: "30px",
                                    fontSize: { xs: "10px", sm: "14px", md: "14px", lg: "14px" },
                                }}
                            >
                                Message
                            </Button>
                        </div>
                    </div>
                }
            </Box>
        </Card >


    )
}
export default DoctorsInfoCard;