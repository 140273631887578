import { Autocomplete, Avatar, Box, Card, debounce, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { D_API } from "../../../../../../services/APIService";
import HealthDeptIcon from "../../../../../../icons/Healthcare_Department.svg";
import { Add, Dehaze, Search } from "@mui/icons-material";
import ParticularHealthDepartmentDemo from "./particularHealthDepartDemo";

const Departments = ({ selectedDept, tabs, setSelectedServ }) => {
    const userOrg = useSelector(state => state.registration.userOrg);
    const healthDeptOrg = useSelector(state => state.registration.healthDeptDetails);
    const healthDeptLoc = useSelector(state => state.registration.healthDeptLocationDetails);
    const [listOfHealthDept, setListOfHealthDept] = useState([]);
    const [filteredHealthDept, setFilteredHealthDept] = useState([]);
    const [value, setValue] = useState(null)
    const [departmentId, setDepartmentId] = useState(null)

    useEffect(() => {
        if (tabs != 4) {
            setSelectedServ(null)
        }
    }, [])
    useEffect(() => {
        if (userOrg?.id) {

            D_API.get(`${userOrg?.id}/resource/authorization/docsteth/Organization?partof=${userOrg?.id}&type=dept`).then((res) => {
                console.log(res.data, "7878")
                let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value) : []
                setListOfHealthDept(sortList);
                // setListOfHealthDept(res?.data?.entry);
            }).catch((err) => console.log(err));
        }
    }, [healthDeptLoc, healthDeptOrg, userOrg])
    console.log(listOfHealthDept, "mani900")

    useEffect(() => {
        if (value?.length > 0) {
            const filteredList = listOfHealthDept.filter((dept) =>
                dept?.resource?.name?.toLowerCase().includes(value.toLowerCase()) ||
                dept?.resource?.alias?.[0]?.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredHealthDept(filteredList);
        } else {
            setFilteredHealthDept(listOfHealthDept); // Reset to full list when input length is less than 3
        }
    }, [value, listOfHealthDept])
    console.log(filteredHealthDept, "filteredHealthDept")

    useEffect(() => {
        if (selectedDept) {
            handleDepartment(selectedDept)
        }
    }, [selectedDept])

    const handleDepartment = (val) => {
        setDepartmentId(val)
        setValue(null)
    }
    console.log(departmentId, "val90")
    return (
        <Box sx={{ padding: "7px 0px", backgroundColor: "#F4F2EE" }}>
            <Grid container sx={{ height: "100%", }} spacing={2} style={{}}>
                <Grid item xs={12} sm={12} md={5} lg={3}
                    sx={{ height: "400px" }}
                >
                    <Card className="ph2" sx={{
                        marginTop: "5px",
                        borderRadius: "10px", height: { sm: "100%", md: "100%", lg: "100%" },
                        boxShadow: "0px 0px 1.6px #888",  // Adjust height as needed

                    }
                    } >
                        <Box className="mt2" sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="p" sx={{ fontSize: "18px", fontWeight: 600, color: "#3084BE", }}>Healthcare Department</Typography>
                            <Box sx={{ display: "flex" }}>
                                <Dehaze sx={{ fontSize: "18px", margin: "3px 8px 0 0" }} />
                                <Box sx={{ width: "18px", height: '18px', borderRadius: "2px", border: '1px solid #888', marginTop: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Add />
                                </Box>
                            </Box>
                        </Box>

                        <Box className="mt2" sx={{
                            width: "96%", padding: "0 4px", height: '36px', border: "1px solid #e0e0e0", borderRadius: "10px",
                            display: "flex", alignItems: "center"
                        }}>
                            <Search sx={{ fontSize: "30px" }} />
                            <TextField
                                id="name"
                                sx={{
                                    width: "85%",
                                    margin: "0 0 0px 6px",
                                    "& .MuiInput-underline:before": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                        borderBottom: "none",
                                    }
                                }}
                                value={value || ""}
                                onChange={(event) => {
                                    console.log(event.target.value, "900")
                                    setValue(event.target.value)
                                }}
                                placeholder="Search Healthcare Department"
                                type="text"
                                variant="standard"
                            />
                        </Box>

                        <Box sx={{
                            // border: "1px solid black",
                            overflowY: 'scroll',
                            mt: 1,
                            height: { xs: '100vh', sm: '100vh', md: '38vh' },
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            }
                        }}>
                            {
                                filteredHealthDept?.length > 0 && filteredHealthDept?.map((val) => {

                                    return (
                                        // <Grid item xs={12} sm={12} md={12} lg={12}
                                        //     sx={{ padding: "0 8px" }}
                                        // >
                                        <Card sx={{
                                            width: "94%", marginLeft: "2px",
                                            padding: "5px 5px 0 6px", borderRadius: "", height: { sm: "60px", md: "60px", lg: "60px" },
                                            backgroundColor: departmentId?.id === val?.resource?.id ? "lightblue" : "none",
                                            boxShadow: "0px 0px 1.6px #888", display: "flex", alignItems: "center",
                                            mb: 2
                                        }} className='mt1 cursor'
                                            onClick={() => {
                                                handleDepartment(val?.resource)
                                                //     history.push({
                                                //         pathname: `/admin/organization/${val?.resource?.id}/healthDepartment`,
                                                //         state: val
                                                //     }
                                                //     );
                                                //     dispatch(getUserOrg(userOrg))
                                            }}
                                        >

                                            <div style={{ width: "100%", display: "flex", gap: "10px", alignItems: "center" }}>
                                                <div>
                                                    <Avatar variant="square" sx={{
                                                        width: '40px !important', height: '40px !important',

                                                    }}
                                                        src={val?.resource?.identifier?.filter((val) => val["system"] == `${process.env.REACT_APP_API_URL}v1/logo` && val["value"]?.startsWith("https"))?.[0]?.value || HealthDeptIcon} />
                                                </div>

                                                <div style={{ height: "40px", width: "100%" }}>
                                                    <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                                    <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.alias?.[0]}</div>
                                                </div>
                                            </div>
                                        </Card>
                                        // </Grid>
                                    )
                                }
                                )
                            }
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={9}
                    sx={{ height: "100%" }}>

                    {
                        departmentId &&
                        (
                            // <Card sx={{
                            //     padding: "5px", borderRadius: "", height: "100%",
                            //     boxShadow: "0px 0px 5px #888",
                            //     overflowY: "scroll",
                            //     "&::-webkit-scrollbar": {
                            //         width: "3px",
                            //     },
                            //     "&::-webkit-scrollbar-thumb": {
                            //         backgroundColor: "#888",
                            //         borderRadius: "10px",
                            //     },
                            //     "&::-webkit-scrollbar-thumb:hover": {
                            //         backgroundColor: "#555",
                            //     },
                            //     scrollbarWidth: "thin",
                            //     scrollbarColor: "#888 #f0f0f0",
                            // }}>
                            <ParticularHealthDepartmentDemo departmentId={departmentId} />
                            // </Card>
                        )
                    }

                </Grid>

            </Grid >

        </Box >
    )
}
export default Departments