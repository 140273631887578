import React, { useState, useEffect, useContext } from 'react';
import { Avatar, Card, Grid, IconButton, Tooltip } from '@mui/material';
import "./facilityDetails.css";
import OrgLogo from '../../../../../../images/orglogo.svg';
import { GrAddCircle } from 'react-icons/gr';
import { FiEdit3 } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreateOrg } from "../create";
import { URI } from '../../../../../utility/uri';
import { D_API } from '../../../../../../services/APIService';
import EditFacilityListDailog from './editFacilityListDailog';
import EditOrgComponent from "../editProfile";
import { fetchSubOrganization, getUserOrg, handleUserOrg } from '../../../../../../redux/Actions/regActions';
import axios from 'axios';
import { ContextInfo } from "../adminOrganisationNew";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const FacilityDetails = ({ parentId }) => {

    const orgData = useSelector((state) => state.registration.userOrg);
    const state = useSelector(state => state.userProfile.userProfile);
    // console.log(state, "vishnu")
    // console.log(orgData, "kit")

    const subOrgList = useSelector((state) => state.registration.listOfSubOrganizations);
    const [createSubOrg, setCreateSubOrg] = useState(false);
    const [editOrg, setEditOrg] = useState(false);
    const [facilityList, setFacilityList] = useState([]);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const isAdmin = useSelector(state => state.registration?.isAdmin);
    console.log(subOrgList, "suss")

    const contextDetails = useContext(ContextInfo);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();


    useEffect(() => {
        if (subOrgList?.length > 0) {
            let sortedList = subOrgList?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value);
            if (sortedList?.length > 0) {
                setFacilityList(sortedList);
            } else {
                setFacilityList([]);
            }
        } else {
            setFacilityList([]);
        }

    }, [subOrgList])


    const handleOpnSubOrganization = () => {
        setCreateSubOrg(true)
    }

    const handleClsSubOrganization = () => {
        setCreateSubOrg(false);
        setEditOrg(false);
    }

    const handleOpnEditDialog = () => {
        setOpenEditDialog(true)
    }

    const handleClsEditDialog = () => {
        setOpenEditDialog(false)
    }


    const getOrganizationInfo = (orgId) => {
        if (orgId) {
            D_API.get(`${orgData?.id}/${URI.organization}/${orgId}`).then((res) => {
                dispatch(getUserOrg(res.data))
                dispatch(handleUserOrg(res.data))
            }).catch((err) => console.log(err))
        }

    }

    const getOrganizationLocation = (orgId) => {
        if (orgId) {
            D_API.get(`${orgData?.id}/${URI.getLocation}${orgId}`).then((res) => {
                dispatch({
                    type: "location",
                    payload: { ...res.data }

                })

            }).catch((err) => console.log(err))
        }
    }

    console.log(facilityList, "zero")

    const handleEditFacility = (facilityId, orgObj) => {

        let list = [...facilityList];
        let editFacilityInfo = list?.filter((e) => e?.resource?.id == facilityId);
        console.log(editFacilityInfo, "ivve")
        if (editFacilityInfo?.[0]?.resource?.id) {
            let id = editFacilityInfo?.[0]?.resource?.id
            getOrganizationInfo(id);
            getOrganizationLocation(id);
            handleClsEditDialog();
            setEditOrg(true);
            // contextDetails.getsubOrgDetails(orgObj?.id);
        }

    }

    const handleEditOrg = () => {
        setEditOrg(false)
    }


    console.log(orgData, "facility", subOrgList)
    return (
        <div className='mt1-5' style={{ borderRaidus: "10px" }}>
            {createSubOrg && <CreateOrg close={handleClsSubOrganization} branch="subBranch" parentOrgObj={orgData} />}
            {editOrg && <EditOrgComponent edit={true} close={handleEditOrg} branch="subBranch" parentOrgObj={orgData} />}

            <EditFacilityListDailog open={openEditDialog} close={handleClsEditDialog} facilityList={subOrgList} editFacilityFn={handleEditFacility} />
            <Card className='p1' sx={{ boxShadow: "0px 0px 1.6px #888", }}>
                <div className='flx-center justify-between'>
                    <div className='title'>
                        Facility Branches
                    </div>
                    {
                        isAdmin === "PERMIT" &&
                        <div className='flx-center'>
                            <div>
                                <Tooltip className='' title={`Add SubOrganization`}>
                                    <IconButton aria-label="visible" onClick={handleOpnSubOrganization} >
                                        <GrAddCircle color='#2E3B43' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title={`Edit SubOrganization`}>
                                    <IconButton aria-label="visible" onClick={handleOpnEditDialog}>
                                        <FiEdit3 color='#2E3B43' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>
                    }

                </div>

                {/* <div>
                    {
                        subOrgList?.length > 0 ?
                            subOrgList?.map((val) => {
                                return (

                                    <Card sx={{ padding: "5px", borderRadius: "", }} className='mv1 cursor' onClick={() => {
                                        history.push(`/admin/organization/${val?.resource?.identifier?.[1]?.value}`)
                                    }} >
                                        <Grid container alignItems="" flexWrap="" spacing={1}>
                                            <Grid item >

                                                <div id="" style={{ height: '40px', width: '40px', borderRadius: '7px' }}>
                                                    <div style={{ backgroundColor: '#A8E8FE', width: '40px', height: "40px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "7px" }}>
                                                        <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                                                    </div>
                                                </div>

                                            </Grid>
                                            <Grid item >
                                                <div>
                                                    <div className='fntMd fntSemiBold'>{val?.resource?.name?.length > 25 ? <span>{val?.resource?.name?.slice(0, 25)}...</span> : <span>{val?.resource?.name}</span>}</div>
                                                    <div className='fntXSm'>{val?.resource?.alias?.[0]?.length > 30 ? `${val?.resource?.alias?.[0]?.slice(0, 30)}...` : val?.resource?.alias?.[0]}</div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>

                                )
                            })
                            : ""

                    }
                </div > */}

                <Grid container spacing={""} >
                    {facilityList?.length > 0 ? (
                        facilityList?.map((val) => (
                            <Grid item xs={12} sm={12} md={12} key={val?.resource?.id}>
                                <Card sx={{ padding: '10px', borderRadius: '7px' }} className='mv0-5 cursor' onClick={() => {
                                    history.push(`/admin/organization/${val?.resource?.identifier?.[1]?.value}`);
                                }}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            {
                                                val?.resource?.identifier?.filter((e) => e.system == `${process.env.REACT_APP_API_URL}v1/orgImg` && e.value?.startsWith("https"))?.length == 1 ?
                                                    <div style={{ height: '40px', width: '40px', borderRadius: '7px' }}>
                                                        <img src={val?.resource?.identifier?.filter((e) => e.system == `${process.env.REACT_APP_API_URL}v1/orgImg` && e.value?.startsWith("https"))?.[0]?.value} width="40px" height="40px" style={{ borderRadius: "7px" }} />

                                                    </div>
                                                    :

                                                    <div style={{ height: '40px', width: '40px', borderRadius: '7px' }}>
                                                        <div style={{ backgroundColor: '#A8E8FE', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '7px' }}>
                                                            <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                                                        </div>

                                                    </div>
                                            }

                                        </Grid>
                                        <Grid item xs>
                                            <div>
                                                <div className='fntMd fntSemiBold capitalize'>
                                                    {val?.resource?.name?.length > 24 ? <span>{val?.resource?.name?.slice(0, 24)}...</span> : <span>{val?.resource?.name}</span>}
                                                </div>
                                                <div className='fntXSm capitalize'>
                                                    {val?.resource?.alias?.[0]?.length > 25 ? `${val?.resource?.alias?.[0]?.slice(0, 25)}...` : val?.resource?.alias?.[0]}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        ""
                    )}
                </Grid>
                {
                    subOrgList?.length <= 0 && <p style={{ textAlign: "", marginTop: "10px" }}>No Facilities Available</p>
                }
            </Card >
        </div >
    )
}

export default FacilityDetails;