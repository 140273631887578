import React, { useEffect } from "react";
import { Card, Grid, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector, useDispatch } from "react-redux";
// import HealthServiceArticles from "../feedcomponents/articles";
import HealthServicePosts from "../feedcomponents/posts";
import { D_API } from "../../../../../../services/APIService";
import { handleUserOrg } from "../../../../../../redux/Actions/regActions";
import "./postsandarticles.css"
const PostsAndArticlesInHealthsServices = () => {
    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    const dispatch = useDispatch();
    // console.log(healthServiceOrg, "5")
    const orgData = useSelector(state => state.registration.userOrgData);

    useEffect(() => {
        if (healthServiceOrg?.providedBy?.reference?.split("/")?.[1]) {

            D_API.get(`${orgData?.id}/Organization/${healthServiceOrg?.providedBy?.reference?.split("/")?.[1]}`).then((res) => {
                dispatch(handleUserOrg(res.data));
                console.log(res.data)
            }).catch((err) => console.log(err));
        }

    }, [healthServiceOrg])

    // console.log(orgData, "orgData22")

    return (
        <div>

            <div className="mt2">
                <Card sx={{ padding: "10px", minHeight: "150px", boxShadow: "0px 0px 1.6px #888" }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div>

                                <Typography className="capitalize" sx={{
                                    fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                    "&.MuiTypography-root": {
                                        lineswhiteSpace: 0,
                                        fontFamily: "unset"
                                    }

                                }}>Posts & Articles</Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: "2px" }}>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" />
                            </div>
                        </Grid>
                    </Grid>

                    {/* <HealthServiceArticles /> */}
                    <div >
                        <HealthServicePosts PageId={orgData?.identifier?.[1]?.value} />
                    </div>

                </Card>
            </div >

        </div >
    )
}
export default PostsAndArticlesInHealthsServices;