import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Organisation from '../../../Atom/modifiedicons/Organisations.svg'
import { updateProfile } from '../../../../redux/Actions/actions';
import { FiEdit3 } from 'react-icons/fi'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import scroll from "../../../Atom/svg/scroll.svg";
import DialogBox from '../../../DialogBox/dialog';
import Layout from './layout';
import Add from './eduCrud';
import URL from '../../../apiurl';
import Updatedcap from '../../../../icons/updatedcap.svg';
import qualificationlogo from '../../../../icons/qualificationlogo.svg';
import '../updateprofile.css'
import OrgLogo from '../../../../images/orglogo.svg'
import { logDOM } from '@testing-library/react';
import UserService from '../../../../services/UserService';
import { D_API, D_API_FHIR, DOCS_WITHOUT_TOKEN } from '../../../../services/APIService';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getPractitionerFromFhir } from '../../../../redux/Actions/regActions';

const QuaView = (props) => {
    console.log(props, "props")
    const [modal, setModal] = useState({ open: false, id: null })
    const [edu, setEdu] = useState([]);
    return (
        <div className="">
            <DialogBox open={modal.open} width="xs" handleDialog={() => setModal({ open: false, id: null })} Content={<p>Do you want to delete ?</p>}
                Actions={<div>
                    <Button variant="contained" tabIndex={1} className="add-option" sx={{ mr: 2 }} onClick={() => { props.handleDelete(modal.id); setModal({ open: false, id: null }) }}>Yes</Button>
                    <Button variant="contained" tabIndex={2} className="add-option" onClick={() => setModal({ open: false, id: null })}>No</Button>
                </div>} />
            <div className="sidebar__stat sidebar__padding">
                <div className="sidebar__substat ta__center ">
                    <img src={Updatedcap} alt="Scholar" />
                    <div className="fonthead fw__semibold sidebar__lh sidebar__padding" style={{ color: '#007cdc' }}>Qualification Details</div>
                </div>
            </div>
            {/* org */}
            {/* old */}
            {/* <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            className="list"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {props.edu && props.edu.length > 0 ? props.edu.map((e, i) => (
                                <Draggable
                                    key={i}
                                    draggableId={i.toString()}
                                    index={i}
                                    className="lol"
                                >
                                    {(provided, snapshot) => (
                                        <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" key={i}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                           
                                            <div className="sidebar__substat ta__center">

                                                <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                                    <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                                </div>
                                                <div className="sidebar__ml " style={{ fontSize: '13px', textTransform: 'capitalize' }}>
                                                    <span style={{ paddingBottom: '8px', fontWeight: '550' }}>{e.code.coding[0].code}</span>{" "}{"-"}{" "}
                                                    <span style={{ paddingBottom: '3px', fontWeight: "550" }}>{e.code.text}</span>
                                                    <p>{e.issuer}</p>
                                                   
                                                </div>
                                            </div>
                                            <div className="sidebar__statNumber d-flex e-align">
                                                <div style={{ padding: '8px', marginRight: '8px' }}>
                                                    <img src={scroll} alt="scroll" />
                                                </div>
                                                <Tooltip title="Edit Qualification">
                                                    <IconButton onClick={() => props.handleEdit(e, i)}>
                                                   
                                                        <FiEdit3 style={{ fontSize: '1.3rem', color: '#000000bd' }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Qualification">
                                                    <IconButton onClick={() => setModal({ open: true, id: i })}>
                                                        <DeleteIcon sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>)}
                                </Draggable>

                            )) :

                                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show">
                                    <p>No Qualification</p>
                                </div>

                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext> */}
            <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            className="list"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {props.edu && props.edu.length > 0 ? props.edu.map((e, i) => (
                                <Draggable
                                    key={i}
                                    draggableId={i.toString()}
                                    index={i}
                                    className="lol"
                                >
                                    {(provided, snapshot) => (
                                        <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" key={i}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >

                                            <div className="sidebar__substat ta__center">

                                                <div className="my-img domain-icon" style={{ height: '55px', width: '55px', borderRadius: '7px' }}>
                                                    <img src={Organisation} alt="organisation" width='25px' height="20px" />
                                                </div>
                                                <div className="sidebar__ml " style={{ fontSize: '13px', textTransform: 'capitalize' }}>
                                                    <span style={{ paddingBottom: '8px', fontWeight: '550' }}>{e?.course?.display}</span>{" "}{"-"}{" "}
                                                    {/* <span style={{ paddingBottom: '3px', fontWeight: "550" }}>{e.code.text}</span> */}
                                                    <p>{e?.issuer?.name}</p>

                                                </div>
                                            </div>
                                            <div className="sidebar__statNumber d-flex e-align">
                                                <div style={{ padding: '8px', marginRight: '8px' }}>
                                                    <img src={scroll} alt="scroll" />
                                                </div>
                                                <Tooltip title="Edit Qualification">
                                                    <IconButton onClick={() => props.handleEdit(e, i)}>

                                                        <FiEdit3 style={{ fontSize: '1.3rem', color: '#000000bd' }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Qualification">
                                                    <IconButton onClick={() => setModal({ open: true, id: i })}>
                                                        <DeleteIcon sx={{ color: 'red' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>)}
                                </Draggable>

                            )) :

                                <div className="sidebar__stat sidebar__padding bg__white e-align icon-show">
                                    <p>No Qualification</p>
                                </div>

                            }
                            {/* ))} */}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    )
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default function Qualification(props) {

    const [edu, setEdu] = useState([])
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [check, setCheck] = useState(true)
    const [edit, setEdit] = useState(false)
    const [id, setId] = useState("");
    const [view, setView] = useState(false)

    const [count, setCount] = useState(3)
    const [user, setUser] = useState({})
    const [educationDetails, setEducationDetails] = useState([]);
    const [licenceDetails, setLicenceDetails] = useState([]);
    const [usersDatas, setUsersDatas] = useState();
    const [identifyHpr, setIdentifyHpr] = useState("");


    const education = useSelector((state) => state.userProfile.user);
    const practitionerDetails = useSelector(state => state.registration.fhirUserData)
    console.log(education, "educ1")

    // newcode 
    const [partQualifDetails, setPartQualifDetails] = useState({
        degree: null, course: null, doc: '', orgName: '', issuer: '', identifier: '', dateIss: null
    })


    const [state, setState] = useState(
        { id: null, degree: "", course: '', from: '', to: '', identifier: '', issuer: '', organisation: '' })

    useEffect(() => {

        dispatch(getPractitionerFromFhir());
    }, [practitionerDetails?.id])

    // console.log(practitionerDetails,"okay")

    useEffect(() => {
        let data = practitionerDetails;
        if (Object?.keys(data)?.length > 0) {
            let name = data.name?.[0]?.given?.[0];
            // let address = data.practioner.address ? data.practioner.address[0] : []

            let cerQualifications = [];
            let licenceDet = [];
            data?.qualification?.forEach((qualification) => {
                if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
                    cerQualifications.push({
                        degree: {
                            system: qualification?.code?.coding?.[0]?.system,
                            display: qualification?.code?.text
                        },
                        course: qualification?.code?.coding?.[0],
                        orgName: "rty",
                        issuer: {
                            name: qualification?.issuer?.display,
                            id: qualification?.issuer?.reference?.split('/')?.[1]
                        },
                        dateIss: qualification?.period?.end,
                        identifier: qualification?.identifier?.[0]?.value

                    })
                }
                else {
                    licenceDet.push({
                        council: qualification?.issuer?.display,
                        regNo: qualification?.identifier?.[0]?.value,
                        date: qualification?.period?.start,
                        dueDate: qualification?.period?.end
                    });
                }
            })

            setEducationDetails(cerQualifications);
            setLicenceDetails(licenceDet);

            let hasHprorNot = data?.identifier?.some(
                (e) => e.system === "https://healthid.ndhm.gov.in/"
            );

            let indexOfHpr =
                data?.identifier?.findIndex(
                    (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
                ) != -1 && hasHprorNot
                    ? data?.identifier?.findIndex(
                        (e) => e.system === "https://healthid.ndhm.gov.in/" && e.value
                    )
                    : -1;
            //   console.log(hasHprorNot,"hasHprorNot",indexOfHpr)
            let getHprId =
                hasHprorNot && indexOfHpr != -1
                    ? data?.identifier[indexOfHpr].value
                    : "";
            setIdentifyHpr(getHprId ? getHprId : "");

            setEdu(data.qualification)
            setUser({
                family: data?.name?.[0]?.family,
                firstName: data?.name?.[0]?.given[0],
                middleName: data?.name?.[0]?.given[1],
                prefix: data?.name?.[0]?.prefix ? data?.name?.[0]?.prefix?.[0] : '',
                suffix: data?.name?.[0]?.suffix ? [...data?.name?.[0]?.suffix] : "",
                birthDate: data?.birthDate,
                qualification: data?.qualification,
                text: data?.name?.[0]?.text,
                languages: data?.communication?.length > 0 ? data?.communication?.map(e => e?.coding?.[0]) : [],
                gender: data.gender,
                address: data?.address,
                contact: data?.telecom,
                // caption: data.captionAndTaglines,
                // about: data.about,
                // id: data.id,
                // photo: data.practioner.photo[0].url,
                // coverPic: data.coverPic,
                // languages: data?.practioner?.communication?.length > 0 ? data?.practioner?.communication?.map(e => e?.language?.coding?.[0]) : []
            })
            // const addressData = {
            //         facilityName: address.facilityName ? address.facilityName : '',
            //         text: address.text,
            //         line1: address.hasOwnProperty('line') ? address?.line[0] : '',
            //         line2: address.hasOwnProperty('line') ? address?.line[1] : '',
            //         city: address.city,
            //         state: address.state,
            //         postalCode: address.postalCode,
            //         country: address.country,
            //         district: address.district,
            //     }


            // const addressData = {
            //     text: [`${data?.address?.[0]?.line?.[0]},${data?.address?.[0]?.line?.[1]},${data?.address?.[0].city},${data?.address?.[0]?.state},${data?.address?.[0]?.country},${data?.address?.[0]?.postalCode}`]?.filter((val) => Boolean(val)),
            //     line1: data?.address?.[0]?.line?.[0] ? data?.address?.[0]?.line?.[0] : "",
            //     line2: data?.address?.[0]?.line?.[1] ? data?.address?.[0]?.line?.[1] : '',
            //     city: data?.address?.[0].city ? data?.address?.[0].city : "",
            //     state: data?.address?.[0]?.state ? data?.address?.[0]?.state : "",
            //     postalCode: data?.address?.[0]?.postalCode ? data?.address?.[0]?.postalCode : "",
            //     country: data?.address?.[0]?.country ? data?.address?.[0]?.country : "",
            //     district: data?.address?.[0].city,
            // }
            // setAddress(addressData)

        }

    }, [practitionerDetails])

    useEffect(() => {
        let eduDet = education
        if (eduDet !== undefined && Object?.keys(eduDet)?.length > 0) {
            let data = eduDet.user
            let name = data.practioner.name[0]

            setUser({
                // family: name.family,
                // firstName: name.given[0],
                // middleName: name.given[1],
                // prefix: name.prefix ? name.prefix[0] : '',
                // suffix: name.suffix,
                // gender: data.practioner.gender,
                // birthDate: data.practioner.birthDate,
                ...user,
                text: name.text,
                caption: data.captionAndTaglines,
                photo: data.practioner?.photo?.[0]?.url,
                about: data.about,
                id: data.id,
                keycloakId: data.keycloakId,
                coverPic: data.coverPic,
                // address: data.practioner.address,
                // contact: data.practioner.telecom,
                // qualification: data.practioner.qualification
            })
            // setEdu(data.practioner.qualification)

            // let cerQualifications = [];
            // let licenceDet = [];
            // data?.practioner?.qualification?.forEach(qualification => {
            //     if (qualification?.identifier?.[0]?.type?.coding?.[0]?.code === "CER") {
            //         cerQualifications.push({
            //             degree: {
            //                 system: qualification?.code?.coding?.[0]?.system,
            //                 display: qualification?.code?.text
            //             },
            //             course: qualification?.code?.coding?.[0],
            //             orgName: "rtyu",
            //             issuer: {
            //                 name: qualification?.issuer?.display,
            //                 id: qualification?.issuer?.reference?.split('/')?.[1],
            //                 code: qualification?.issuer?.code
            //             },
            //             dateIss: qualification?.period?.end,
            //             identifier: qualification?.identifier?.[0]?.value
            //         });
            //     } else {
            //         licenceDet.push({
            //             council: qualification?.issuer?.display,
            //             regNo: qualification?.identifier?.[0]?.value,
            //             date: qualification?.period?.start,
            //             dueDate: qualification?.period?.end
            //         })

            //     }
            // });

            // setEducationDetails(cerQualifications);
            // setLicenceDetails(licenceDet);
        }
    }, [education])

    useEffect(() => {
        DOCS_WITHOUT_TOKEN.get(`Practitioner/identifier/new/dev.docsteth.com/${UserService.getUserId()}`)
            .then(res => {
                // console.log(res)
                setUsersDatas(res?.data?.entry?.[0] || {})
            })
            .catch(err => console.log(err))
    }, []);
    console.log(usersDatas, "first")

    console.log(educationDetails, "educationDetails");



    // const handleChange = (name, value) => {
    //     setState({ ...state, [name]: value })
    // }


    const handleChange = (name, value) => {
        // setState({ ...state, [name]: value })
        setPartQualifDetails({ ...partQualifDetails, [name]: value })
    }

    console.log(partQualifDetails, "particular")

    const handleAdd = () => {

        // console.log(partQualifDetails, "0505", edit)

        let data = [...educationDetails];

        if (partQualifDetails?.course?.code && partQualifDetails?.degree?.system && partQualifDetails?.issuer?.name || partQualifDetails?.issuer && partQualifDetails?.dateIss && partQualifDetails?.identifier) {
            // if (partQualifDetails?.course?.code && partQualifDetails?.degree?.system && partQualifDetails?.issuer?.name && partQualifDetails?.dateIss && partQualifDetails?.identifier) {
            let updatedEducDetails = [...data, { ...partQualifDetails }];
            console.log(data, "data1", id, updatedEducDetails)
            // console.log(usersDatas, "shan")
            let hprIdDetails = identifyHpr;
            dispatch(updateProfile(user, user.contact, user.address, updatedEducDetails, licenceDetails, usersDatas, hprIdDetails))
            setOpen(false)
            // setEdit(false);
            setPartQualifDetails({
                degree: "",
                course: "",
                doc: '',
                orgName: '',
                issuer: '',
                identifier: "",
                dateIss: ""
            })
        } else {
            alert("Unable to Add Qualifications")
        }


        // var qualification = []
        // if (edu !== null) {
        //     qualification = [...edu, setQualification(state)]
        // }
        // else {
        //     qualification = [setQualification(state)]
        // }
        // dispatch(updateProfile(user, user.contact, user.address, qualification, "", usersDatas))
        // setOpen(false)

    }

    const handleCancel = () => {
        // setCheck(false)
        setOpen(false)
        setEdit(false)
        setPartQualifDetails({
            degree: "",
            course: "",
            doc: '',
            orgName: '',
            issuer: '',
            identifier: "",
            dateIss: ""
        })

    }

    const handleEdit = (data, id) => {
        console.log(data, id, "editqualification")
        // new code
        data.dateIss !== null ? setCheck(false) : setCheck(true)
        setEdit(true);
        setOpen(true);

        setPartQualifDetails((prev => {
            return {
                ...prev,
                degree: data?.degree ? data?.degree : null,
                course: data?.course ? data?.course : null,
                doc: '',
                orgName: '',
                issuer: data?.issuer ? data?.issuer : '',
                identifier: data.identifier ? data.identifier : "",
                dateIss: data?.dateIss ? data?.dateIss : null
            }
        }))

        setId(id)




        // old code
        // data.period.end !== null ? setCheck(false) : setCheck(true)
        // setEdit(true)
        // setOpen(true)

        // setState({
        //     degree: { code: data.code.coding[0].code, display: data.code.coding[0].display },
        //     course: data.code.text,
        //     from: data.period.start,
        //     to: data.period.end,
        //     issuer: data.issuer,
        //     identifier: data.identifier.value,
        //      organisation: data.organisation
        // })

        // setId(id)

    }




    console.log(state.degree, "degree");


    const setQualification = (state) => {
        return {
            identifier: {
                system: URL.searchCollege,
                value: state.organisation,
            },
            code: {
                coding: [{
                    system: "http://terminology.hl7.org/CodeSystem/v2-0360/2.7",
                    code: state.degree.code,
                    display: state.degree.display
                }],
                text: state.course
            },
            period: {
                start: state.from,
                end: state.to
            },
            issuer: state.issuer,
            organisation: state.organisation
        }
    }

    const handleUpdate = () => {




        // newcode
        let data = [...educationDetails];

        if (partQualifDetails?.course?.code && partQualifDetails?.degree?.system && partQualifDetails?.issuer?.name && partQualifDetails?.dateIss && partQualifDetails?.identifier) {
            let updatedEducDetails = data.map((d, i) => i == id && partQualifDetails?.course?.code && partQualifDetails?.degree?.system && partQualifDetails?.issuer?.name && partQualifDetails?.dateIss && partQualifDetails?.identifier ? { ...partQualifDetails } : { ...d });
            console.log(data, "data1", id, updatedEducDetails)
            // console.log(usersDatas, "shan")
            let hprIdDetails = identifyHpr;
            dispatch(updateProfile(user, user.contact, user.address, updatedEducDetails, licenceDetails, usersDatas, hprIdDetails))
            setOpen(false)
            setEdit(false);
            setPartQualifDetails({
                degree: "",
                course: "",
                doc: '',
                orgName: '',
                issuer: '',
                identifier: "",
                dateIss: ""
            })
        } else {
            alert("Fill all data for given respectable fields")
        }


        // old code

        // let data = [...edu]
        // const qualification = setQualification(state)
        // data = data.map((d, i) => i === id ? { ...qualification } : { ...d })
        // dispatch(updateProfile(user, user.contact, user.address, data))
        // setOpen(false)
        // setEdit(false)
    }



    const handleDelete = (id) => {
        // let data = [...edu]
        let data = [...educationDetails]
        data = data.filter((d, i) => i !== id)
        let hprIdDetails = identifyHpr;
        dispatch(updateProfile(user, user.contact, user.address, data, licenceDetails, usersDatas, hprIdDetails))
    }

    const addFun = () => {
        setOpen(true)
        setCheck(false)
        setPartQualifDetails({
            degree: "",
            course: "",
            doc: '',
            orgName: '',
            issuer: '',
            identifier: "",
            dateIss: ""
        })
        // setState({ degree: '', course: '', from: null, to: null, identifier: '', issuer: '', organisation: '' })
    }

    const handleOrg = (collegeList) => {
        setState({
            ...state, organisation: collegeList.collegeName || collegeList, issuer: collegeList.univName || collegeList

        })
    }

    const onDragEnd = result => {
        if (!result.destination) return;
        // old code
        // const items = reorder(
        //     edu,
        //     result.source.index,
        //     result.destination.index
        // );
        // setEdu(items);

        // new code
        const items = reorder(
            educationDetails,
            result.source.index,
            result.destination.index
        );
        setEducationDetails(items);
        let hprIdDetails = identifyHpr;
        dispatch(updateProfile(user, user.contact, user.address, items, licenceDetails, usersDatas, hprIdDetails))

    };


    const handleOnClickAway = (event) => {

        // setOpen(false)
        // setEdit(false)
        // setPartQualifDetails({
        //     degree: "",
        //     course: "",
        //     doc: '',
        //     orgName: '',
        //     issuer: '',
        //     identifier: "",
        //     dateIss: ""
        // })

    }
    console.log(education, "jjjjjj");

    console.log(edu, "edu")
    return (
        <div style={{ backgroundColor: "#ffffff", borderRadius: '10px' }}>

            {/* CRUD */}
            {/* old code */}
            {/* <DialogBox open={open} handleDialog={() => setOpen(false)}
                Title={<span className="e-align d-flex"><img src={Updatedcap} alt="Scholar" />&nbsp;Qualification</span>} width="md"
                Content={<Add input={true} state={state} handleChange={handleChange} cancel={() => handleCancel()}
                    handleOrg={handleOrg} handleSubmit={edit ? handleUpdate : handleAdd} submit={edit ? "Update" : "Submit"} check={check} handleCheck={(e) => { setCheck(e.target.checked); handleChange('to', null); }} education={edu} />} */}


            {/* new code */}

            <DialogBox open={open} handleDialog={() => setOpen(false)}
                Title={<span className="e-align d-flex"><img src={Updatedcap} alt="Scholar" />&nbsp;Qualification</span>} width="md"
                Content={<Add input={true} state={partQualifDetails} handleChange={handleChange} cancel={() => handleCancel()}
                    handleOrg={handleOrg} handleSubmit={edit ? handleUpdate : handleAdd} submit={edit ? "Update" : "Submit"} check={check} handleCheck={(e) => { setCheck(e.target.checked); handleChange('to', null); }} education={edu} onBackdropClick={true} />}
            />

            {/* // Actions = {<> {edit ? <Button onClick={(e) => handleUpdate()}>Update</Button> : <Button onClick={(e) => handleAdd()}>Add</Button>}
            // <Button onClick={() => handleCancel()}>Cancel</Button></>} */}

            {/* View */}
            {/* old code */}
            {/* <DialogBox open={view} handleDialog={() => setView(false)}
                Content={<QuaView edu={edu} handleEdit={handleEdit} handleDelete={handleDelete} onDragEnd={onDragEnd} />}
                Actions={<Button onClick={() => setView(false)}>Close</Button>}
                style={{ padding: '0px' }}
            /> */}
            <DialogBox open={view} handleDialog={() => setView(false)}
                Content={<QuaView edu={educationDetails} handleEdit={handleEdit} handleDelete={handleDelete} onDragEnd={onDragEnd} handleSubmit={handleUpdate} />}
                Actions={<Button onClick={() => setView(false)}>Close</Button>}
                style={{ padding: '0px' }}
            />

            <Layout HeadIcon={<img src={qualificationlogo} alt="Scholar" sx={{ borderRadius: '10px' }} />}
                Title="Qualification" handleAdd={() => addFun()} handleView={() => setView(true)}
            />

            {edu !== null && edu?.length > 0 ?
                <DragDropContext>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                className="list"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >

                                {edu.slice(0, count).map((e, index) => (
                                    <Draggable
                                        key={index}
                                        draggableId={index.toString()}
                                        index={index}
                                        className="lol"
                                    >
                                        {(provided, snapshot) => (
                                            <div className="sidebar__stat sidebar__padding bg__white e-align icon-show pointer_none" key={index}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{ borderRadius: '10px' }}
                                            >

                                                {e?.identifier?.[0]?.type?.coding?.[0]?.code !== "LN" && <div className="sidebar__substat ta__center" id="qualification_width_p">
                                                    <div id="qualification_width_ch1" style={{ height: '50px', width: '50px', borderRadius: '7px' }}>
                                                        {console.log(e, "tttyiuouoi99")}

                                                        <div style={{ backgroundColor: '#A8E8FE', width: '55px', height: "55px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "7px" }}>
                                                            <img src={OrgLogo} alt="organisation" width='25px' height="20px" />
                                                        </div>
                                                    </div>
                                                    <div id="qualification_width_ch2" className="sidebar__ml capitalize" style={{ marginLeft: '20px' }}>
                                                        {(e.code && e.code.coding[0].code === "BS") ? <span style={{ fontWeight: '550', paddingBottom: '3px' }}></span> :
                                                            <span className='edu_text'>{""}{" "}{""}{" "}</span>}
                                                        <span className='edu_text'>{e.code?.coding?.[0]?.display}</span>
                                                        <p className='edu_sub_text'>{e.issuer?.display || e.issuer}</p>

                                                        <p className='edu_date_text'>{e.period && e.period.start && moment(e.period.start).format('MMM yyyy')} - {e.period && e.period.end ? moment(e.period.end).format('MMM yyyy') : 'present'}</p>
                                                    </div>
                                                </div>
                                                }
                                            </div>)}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                :
                <div style={{ padding: "15px", borderRadius: "10px" }}>
                    <h2 style={{ fontSize: "18px", fontWeight: "500", textAlign: "center", color: "#36454F" }}>No Data</h2>
                </div>
                // <div className="sidebar__stat sidebar__padding bg__white e-align icon-show contact_left">
                //     <div className="sidebar__substat ta__center">
                //         <div>
                //             <p onClick={() => addFun()}
                //                 className="cursor d-flex e-align follow-link"
                //                 style={{ fontSize: "15px", marginTop: '10px' }}>
                //                 <AddCircleOutlineIcon sx={{ fontSize: '20px' }} />&nbsp;Add Qualification
                //             </p>
                //             <p style={{ fontSize: '14px', marginTop: '6px', color: '#635660' }}>Your education and licenses here</p>
                //         </div>
                //     </div>
                // </div>
            }
            {/* <div className="sidebar__stat sidebar__padding bg__white e-align icon-show" style={{ display: "flex", justifyContent: "center" }}>
                <div className="sidebar__substat ta__center">
                    {edu !== null && edu?.length > 3 ? count === 3 ? <p id="border_exp_btn" onClick={() => setCount(edu?.length)} className="cursor d-flex e-align" style={{ color: '#0137CB' }}>{edu?.length - 3} More Qualification{(edu?.length - 3) > 1 ? 's' : ''}&nbsp;<MdOutlineKeyboardArrowDown /></p> : <p onClick={() => setCount(3)} className="cursor" style={{ color: '#0137CB' }}>Hide</p> : ''}

                </div>
            </div> */}
        </div>
    );
}