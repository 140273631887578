import React, { useEffect, useState } from "react";
import { Button, Card, Paper, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { OrgLayout } from "../../../../common/components/layout";
import { useSelector, useDispatch } from "react-redux";
import { D_API } from "../../../../../services/APIService";
import { URI } from "../../../../utility/uri";

const LocationIcon = () => (
  <LocationOnSharpIcon sx={{ color: "maroon", fontSize: "40px" }} />
);

const HospitalLocationDetails = () => {
  const [loc, setLoc] = useState({ lat: "", lng: "" });
  const [adjust, setAdjust] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: "", lng: "" });
  const userOrg = useSelector((state) => state.registration.userOrg);
  const location = useSelector(state => state.registration.location);
  const dispatch = useDispatch();





  // useEffect(() => {
  //   const add = userOrg?.address?.[0] || {};
  //   setLoc({
  //     lat: parseFloat(add?.line?.[3]?.split(",")[0]),
  //     lng: parseFloat(add?.line?.[3]?.split(",")[1]),
  //   });
  //   setMapCenter({
  //     lat: parseFloat(add?.line?.[3]?.split(",")[0]),
  //     lng: parseFloat(add?.line?.[3]?.split(",")[1]),
  //   });
  // }, [userOrg]);

  useEffect(() => {
    const add = location?.position || {};
    setLoc({
      lat: parseFloat(add?.latitude ? add?.latitude : ""),
      lng: parseFloat(add?.longitude ? add?.longitude : ""),
    });
    setMapCenter({
      lat: parseFloat(add?.latitude ? add?.latitude : ""),
      lng: parseFloat(add?.longitude ? add?.longitude : ""),
    });
  }, [location]);


  const handleCoords = (t) => {
    if (adjust) {
      setLoc({ lat: t.lat, lng: t.lng });
      setMapCenter({ lat: t.lat, lng: t.lng });
    }
  };


  useEffect(() => {
    if (adjust) {
      let data = {
        ...location,
        position: { longitude: parseFloat(loc?.lng), latitude: parseFloat(loc?.lat) }
      }
      D_API.put(`${userOrg?.id}/${URI.addLocation}/${location?.id}`, data).then(res => {
        D_API.get(`${userOrg?.id}/${URI.addLocation}/${location?.id}`).then((res) => {
          dispatch({
            type: "location",
            payload: { ...res.data }

          })
        })
      }).catch((err) => {
        console.log(err)
      })

    }
  }, [setLoc, setMapCenter])


  // console.log(loc, mapCenter, adjust, "000000")
  const locateMap = () => {

    const Location = window.navigator && window.navigator.geolocation;
    if (Location) {
      Location?.getCurrentPosition(
        (position) => {
          setLoc({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setMapCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          alert("Please enable location");
        }
      );
    }
  };

  return (
    <Card style={{ marginTop: "15px", borderRadius: 10 }}>
      <Paper elevation={5} style={{ padding: 10 }}>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          {/* <h1 className="fntMd mt-5" style={{ color: "#1976d2" }}>
            Address
          </h1> */}
          <Typography variant="p" sx={{ fontSize: "18px", fontWeight: 600, color: "#3084BE", }}>Address</Typography>
          <div style={{
            // display: "flex",
            // marginTop: 15,
            // // alignItems: "center", 
            // justifyContent: "center",
            // // gap: 10,

          }}>
            <a
              target="_blank"
              // href={`https://www.google.com/maps?q=${userOrg?.address?.[0]?.line[3]?.split(",")[0]
              //   },${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}
              href={`https://www.google.com/maps?q=${location?.position?.latitude},${location?.position?.longitude}`}
            >
              <Button
                style={{
                  fontSize: "14px",
                  width: "100% !important",
                  textTransform: "capitalize",
                  backgroundColor: "#FFFFFF",
                  color: "#343434",
                  whiteSpace: 'nowrap'
                }}
                variant="contained"
                size="small"
              >
                <DirectionsIcon style={{ fontSize: 14 }} /> &nbsp;Get Directions{" "}
              </Button>
            </a>
          </div>
        </div>
        <div>
          {loc.lat && loc.lng ? (
            <>
              <div
                style={{ height: "250px", width: "100%", position: "relative", }}
                className="pv1-5"
              >
                <Button
                  className="capitalize"
                  sx={{
                    position: "absolute",
                    top: 25,
                    right: 60,
                    zIndex: "99999",
                    backgroundColor: "white!important",
                    color: "black",
                    px: 4,
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => setAdjust(true)}
                >
                  Adjust
                </Button>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBoKERaSenAxv2oAp-Ma3qNVDlJIwh0UY8",
                  }}
                  defaultCenter={mapCenter}
                  defaultZoom={16}
                  zoom={adjust ? 20 : 16}
                  onClick={handleCoords}
                >
                  <LocationIcon lat={loc.lat} lng={loc.lng} />
                </GoogleMapReact>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <p
            style={{ color: "#525252", marginBottom: 20 }}
            className="fntSemiBold"
          >
            {[
              // userOrg?.address?.[0]?.line?.[0],
              // userOrg?.address?.[0]?.district,
              // userOrg?.address?.[0]?.state,
              // userOrg?.address?.[0]?.country,
              // userOrg?.address?.[0]?.postalCode,

              location?.address?.line?.[0],
              location?.address?.line?.[1],
              location?.address?.district,
              location?.address?.state,
              location?.address?.country,
              location?.address?.postalCode,
            ]
              ?.filter(Boolean)
              ?.toString()
              ?.replace(/,/g, ", ") || "N/A"}
          </p>
        </div>



        {/* old code */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 10,
            border: "1px solid red"
          }}
        >
          <h1 className="fntMd mt-5" style={{ color: "#1976d2" }}>
            Address
          </h1>
          {loc.lat && loc.lng ? (
            <>
              <div
                style={{ height: "250px", width: "100%", position: "relative" }}
              >
                <Button
                  className="capitalize"
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 60,
                    zIndex: "99999",
                    backgroundColor: "white!important",
                    color: "black",
                    px: 4,
                  }}
                  size="small"
                  variant="contained"
                  onClick={() => setAdjust(true)}
                >
                  Adjust
                </Button>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBoKERaSenAxv2oAp-Ma3qNVDlJIwh0UY8",
                  }}
                  defaultCenter={mapCenter}
                  defaultZoom={11}
                  zoom={adjust ? 20 : 11}
                  onClick={handleCoords}
                >
                  <LocationIcon lat={loc.lat} lng={loc.lng} />
                </GoogleMapReact>
              </div>
            </>
          ) : (
            ""
          )}

          <p
            style={{ color: "#525252", marginBottom: 20 }}
            className="fntSemiBold"
          >
            {[
              // userOrg?.address?.[0]?.line?.[0],
              // userOrg?.address?.[0]?.district,
              // userOrg?.address?.[0]?.state,
              // userOrg?.address?.[0]?.country,
              // userOrg?.address?.[0]?.postalCode,

              location?.address?.line?.[0],
              location?.address?.district,
              location?.address?.state,
              location?.address?.country,
              location?.address?.postalCode,
            ]
              ?.filter(Boolean)
              ?.toString()
              ?.replace(/,/g, ", ") || "N/A"}
          </p>


          <div style={{
            display: "flex",
            marginTop: 15,
            justifyContent: "center",
          }}>
            <a
              target="_blank"
              // href={`https://www.google.com/maps?q=${userOrg?.address?.[0]?.line[3]?.split(",")[0]
              //   },${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}
              href={`https://www.google.com/maps?q=${location?.position?.latitude},${location?.position?.longitude}`}
            >
              <Button
                style={{
                  fontSize: "14px",
                  width: "100% !important",
                  textTransform: "capitalize",
                  backgroundColor: "#FFFFFF",
                  color: "#343434",
                  whiteSpace: 'nowrap'
                }}
                variant="contained"
              >
                <DirectionsIcon style={{ fontSize: 14 }} /> &nbsp;Get Directions{" "}
              </Button>
            </a>
          </div>
        </div> */}


        {/* 
        <div style={{
          display: "flex",
          marginTop: 15,
          // alignItems: "center",
          justifyContent: "center",
          // gap: 10,

        }}>
          <a
            target="_blank"
            // href={`https://www.google.com/maps?q=${userOrg?.address?.[0]?.line[3]?.split(",")[0]
            //   },${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}
            href={`https://www.google.com/maps?q=${location?.position?.latitude},${location?.position?.longitude}`}
          >
            <Button
              style={{
                fontSize: "14px",
                width: "100% !important",
                textTransform: "capitalize",
                backgroundColor: "#FFFFFF",
                color: "#343434",
                whiteSpace: 'nowrap'
              }}
              variant="contained"
            >
              <DirectionsIcon style={{ fontSize: 14 }} /> &nbsp;Get Directions{" "}
            </Button>
          </a>


        </div> */}
        {/* <div
          style={{
            display: "flex",
            marginTop: 15,
            alignItems: "center",
            gap: 10,
          }}
        >
          <Button
            onClick={locateMap}
            style={{
              fontSize: "14px",
              width: "100%",
              textTransform: "capitalize",
              backgroundColor: "#FFFFFF",
              color: "#343434",
            }}
            variant="contained"
          >
            <MapOutlinedIcon style={{ fontSize: 14 }} />
            &nbsp;Locate
          </Button>
          <a
            target="_blank"
            // href={`https://www.google.com/maps?q=${userOrg?.address?.[0]?.line[3]?.split(",")[0]
            //   },${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}
            href={`https://www.google.com/maps?q=${location?.position?.latitude},${location?.position?.longitude}`}
          >
            <Button
              style={{
                fontSize: "14px",
                width: "100%",
                textTransform: "capitalize",
                backgroundColor: "#FFFFFF",
                color: "#343434",
                whiteSpace: 'nowrap'
              }}
              variant="contained"
            >
              <DirectionsIcon style={{ fontSize: 14 }} /> &nbsp;Get Directions{" "}
            </Button>
          </a>
        </div> */}
      </Paper>
    </Card >
  );
};

export default HospitalLocationDetails;
