import React, { useState } from "react";
import { Grid, Typography } from '@mui/material';
import Feed from "../../Molecule/Feed/Feed";
import Vollmond from '../../../icons/vollmond.svg';
import Downarrow from '../../../icons/downarrow.svg';
const ProfileTimeline = () => {
    const [view, setView] = useState(false)
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} className="margin_myprofile">
                    <div className='border_img mt-10' style={{ boxShadow: "0px 0px 1.6px #888", borderRadius: "10px" }}>
                        <div className='recent_activity dflex_al_jc' style={{ padding: "15px" }}>
                            <h2 style={{ fontSize: "18px", fontWeight: "400", color: "#47AEE1" }}>Recent Activity</h2>
                        </div>
                        <div>
                            <Typography variant='h6' style={{ textAlign: "center", color: "#36454F", fontSize: "18px" }}>No Data</Typography>
                        </div>
                        {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p>Registered Events</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                            </div>
                        </div> */}
                        {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p># Topics</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seemore</h4>
                            </div>
                        </div> */}
                        {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p>Pages</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seemore</h4>
                            </div>
                        </div> */}
                        {/* <div style={{ padding: "10px 20px" }}>
                            <div className='dflex_al_jc'>
                                <div style={{ fontSize: "15px", fontWeight: "400" }}>
                                    <p>People</p>
                                </div>
                                <div>
                                    <img width="10px" height="10px" src={Downarrow}></img>
                                </div>
                            </div>
                            <div className='mt-10' style={{ display: "flex" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "400" }}>seeAll</h4>
                            </div>
                        </div> */}
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Feed view={true}></Feed>
                </Grid>
            </Grid>

        </>
    )
}

export default ProfileTimeline;