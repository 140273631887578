
import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Card, Drawer, Grid, SwipeableDrawer, Modal, Dialog, IconButton, Snackbar, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UploadMediaGallery from "../uploadhealthserviceGallery/uploadingGallery";
import axios from "axios";
import { useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Close, Opacity } from "@mui/icons-material";
import "./galleryMedia.css";
import URL from "../../../../../apiurl";
import SelectedItemGallery from "../uploadhealthserviceGallery/selectedGalleryItem";


const DialogOfGalleryImages = ({ open, close, particularItem, listOfImages, setItem, updatePostedList, getListApi }) => {
    // console.log(props)

    return (

        <Dialog
            open={open}
            onClose={close}
            fullWidth
            maxWidth={'lg'}
            minWidth={'sm'}
        >

            {/* <Drawer
                anchor={'right'}
                open={open}
                onClose={close}

                sx={{
                    '& .MuiPaper-root': {
                        top: '0px!important',
                        right: '0px!important'
                    },
                    // '& .MuiBackdrop-root': {
                    //     opacity: '0.7!important',
                    //     backgroundColor: 'white!important',
                    //     top: '60px!important'
                    // },
                    position: 'absolute',
                    top: '0px',


                }} */}
            {/* > */}
            <div style={{ height: "100vh", overflow: "hidden" }}>
                <SelectedItemGallery particularItem={particularItem} images={listOfImages} close={close} setItem={setItem} updateImageList={updatePostedList} getGallery={getListApi} />
            </div>
            {/* </Drawer > */}

        </Dialog>

    )
}

const GalleryMedia = () => {
    const [open, setOpen] = useState(false);
    const [postedGalleryList, setPostedGalleryList] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const videoRef = useRef("");
    const [alert, setAlert] = useState({
        open: false,
        msg: ""
    })
    const healthServiceLoc = useSelector(state => state.registration.healthServiceLocInfo);
    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);

    // console.log(healthServiceOrg, "mooo")

    const handleOpenUpldMediaGallery = () => {
        setOpen(true)
    }

    const handleCloseUpldMediaGallery = () => {
        setOpen(false);
    }

    useEffect(() => {
        fetchData();
    }, [healthServiceOrg])

    const fetchData = async () => {
        if (healthServiceOrg?.id) {
            try {
                const data = await getPostList(healthServiceOrg?.id);
                setPostedGalleryList(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setAlert({
                    open: true, msg: "Error while fetching data"
                })
            }
        } else {
            setPostedGalleryList([]);
        }

    };

    const getPostList = async (Id) => {
        if (Id) {
            try {
                const response = await axios.get(`${URL.getGalleryDetailsByHealthServiceId}${Id}`);
                return response.data; // Return the actual data from the response
            } catch (err) {
                setAlert({
                    open: true, msg: "Error while fetching data"
                })
                console.error('Error fetching data:', err);
                throw err; // Re-throw the error to handle it somewhere else if needed
            }
        }
    }

    const chunkArray = (arr, size) => {
        return arr?.reduce((acc, _, i) => {
            if (i % size === 0) {
                acc.push(arr?.slice(i, i + size));
            }
            return acc;
        }, []);
    };


    const chunkedItems = chunkArray(postedGalleryList, 6);



    const handleOpenDrawer = () => {
        setOpenDrawer(true)

    }

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
    }





    // dont move code any where it should always be above the return only
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                    setAlert({
                        open: false,
                        msg: ""
                    })
                }}
            >
                <Close fontSize="small" />
            </IconButton>
        </React.Fragment>
    );





    return (
        <>
            <div className="mt2">
                <Card sx={{ padding: "10px", minHeight: "150px", boxShadow: "0px 0px 1.6px #888" }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <div>

                                <Typography className="capitalize" sx={{
                                    fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                    "&.MuiTypography-root": {
                                        lineswhiteSpace: 0,
                                        fontFamily: "unset"
                                    }

                                }}>Media Gallery</Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div>
                                <AddCircleIcon fontSize="small" sx={{ color: "#008114", }} className="cursor" onClick={handleOpenUpldMediaGallery} />
                            </div>
                        </Grid>
                    </Grid>
                    <UploadMediaGallery open={open} close={handleCloseUpldMediaGallery} getListOfGallery={fetchData} />

                    <>
                        {
                            chunkedItems?.length > 0 &&

                            <Carousel
                                className="cursor"
                                style={{ height: "200px", width: "100%", border: "1px solid red", }}
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                    hasPrev && (
                                        <button type="button" onClick={onClickHandler} title={label}
                                            className="carousalprevsBtn" >
                                            <KeyboardArrowLeftIcon />
                                        </button>
                                    )
                                }
                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                    hasNext && (
                                        <button type="button" onClick={onClickHandler} title={label}
                                            className="carousalhasNextBtn "
                                        >
                                            <KeyboardArrowRightIcon />
                                        </button>
                                    )
                                }

                            >
                                {
                                    chunkedItems?.map((chunk, index) => (
                                        <div key={index} style={{ display: 'flex' }}>
                                            {chunk?.map((item, i) => (
                                                <Card
                                                    key={i}
                                                    style={{ width: "200px", height: "200px", margin: "5px" }}
                                                    onClick={() => {
                                                        handleOpenDrawer();
                                                        setSelectedItem(item);
                                                    }}
                                                >
                                                    {
                                                        item?.mediaType == "IMAGE" && <img
                                                            src={item.sourceLink}
                                                            alt=""
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        />
                                                    }
                                                    {
                                                        item?.mediaType == "VIDEO" &&
                                                        item?.sourceLink !== null && <video style={{ backgroundColor: 'black' }} width="100%" height="100%" src={item?.sourceLink} controlsList="nodownload" controls ref={videoRef} />
                                                    }

                                                </Card>
                                            ))}
                                        </div>
                                    ))
                                }
                            </Carousel >
                        }
                    </>
                    <>
                        {
                            chunkedItems?.length <= 0 &&
                            <div className="flx-center justify-center fntMd  fntSemiBold " style={{ height: "150px", }} >
                                <div >
                                    No Data is Available
                                </div>

                            </div>
                        }
                    </>
                </Card >


                <DialogOfGalleryImages open={openDrawer} close={handleCloseDrawer} particularItem={selectedItem} listOfImages={postedGalleryList} setItem={setSelectedItem} updatePostedList={setPostedGalleryList} getListApi={fetchData} />

                <Snackbar
                    // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={alert.open}
                    action={action}
                    message={alert.msg}
                />

            </div >



        </>
    )
}

export default GalleryMedia;
