import React from "react";
import { Grid, Card, Typography } from "@mui/material";
import { useSelector } from "react-redux";


const EligibilityCriteria = () => {
    const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
    // console.log(healthServiceOrg, "5")
    return (
        <div>

            <div className="mt2">
                <Card sx={{ padding: "10px", boxShadow: "0px 0px 1.6px #888", }}>
                    <div className="font-head fntSemiBold">Eligibilty</div>
                    {
                        healthServiceOrg?.eligibility?.length > 0 &&
                        <Grid container pt={1} xs={12}>
                            <Grid item>

                                <div className="pb0-5">
                                    <div >
                                        <Typography className="capitalize" sx={{
                                            fontSize: "18px", fontWeight: 600, color: "#3084BE",
                                            "&.MuiTypography-root": {
                                                lineswhiteSpace: 0,
                                                fontFamily: "unset"
                                            }

                                        }}>Eligible HealthCare Programs</Typography>
                                    </div>

                                    <div className="flx-center" style={{ gap: 10 }}>
                                        {healthServiceOrg?.eligibility?.map((e) => (
                                            <div key={e.id} style={{ backgroundColor: "#E2DDDD", borderRadius: "15px", height: "25px" }} className="ph1 fntSm flx-center">
                                                {e?.code?.coding?.[0]?.display}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    }

                    {/* <Grid container pt={1} xs={12}>
                        <Grid item>
                            <div className="pb0-5">
                                <div className="fntSm fntSemiBold pb0-5">Eligible HealthCare Programs</div>
                                {healthServiceOrg?.eligibility?.length > 0 &&
                                    <div className="flx-center" style={{ gap: 10 }}>
                                        {healthServiceOrg?.eligibility?.map((e) => (
                                            <div key={e.id} style={{ backgroundColor: "#E2DDDD", borderRadius: "15px", height: "25px" }} className="ph1   fntSm flx-center">
                                                {e?.code?.coding?.[0]?.display}
                                            </div>
                                        ))}
                                    </div>
                                }

                            </div>
                        </Grid>
                    </Grid> */}
                    <Grid container pt={1} xs={12}>
                        <Grid item>
                            <div className="fntSm fntSemiBold">Appointment Required:</div>
                        </Grid>
                        <Grid item>
                            <div className="fntSm flx-center">
                                &nbsp;{healthServiceOrg?.appointmentRequired == true ? "Yes" : "No"}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container pt={1} xs={12}>
                        <Grid item>
                            <div className="fntSm fntSemiBold">Require Referral:</div>
                        </Grid>
                        <Grid item>
                            <div className="fntSm flx-center">
                                &nbsp;{healthServiceOrg?.referralMethod ? "Yes" : "No"}
                            </div>
                        </Grid>
                    </Grid>




                    {healthServiceOrg?.eligibility?.length > 0 &&



                        <Grid container pt={1} xs={12}>
                            {

                                healthServiceOrg?.eligibility?.map((e) => (
                                    <>
                                        <Grid item>
                                            <div className="fntSm fntSemiBold">EligibilityCode:</div>
                                        </Grid>
                                        <Grid item>
                                            <div className="flx-center" style={{ gap: 0 }}>
                                                <div key={e.id} className="fntSm flx-center">
                                                    &nbsp;{e?.comment},
                                                </div>

                                            </div>
                                        </Grid>
                                    </>
                                ))}

                        </Grid>

                    }
                </Card>
            </div>

        </div >
    )
}
export default EligibilityCriteria;